import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DbService } from '../db.service';
import { PerformanceTableDataSource, PerformanceTableItem } from './performance-table-datasource';

@Component({
  selector: 'app-performance-table',
  templateUrl: './performance-table.component.html',
  styleUrls: ['./performance-table.component.css']
})
export class PerformanceTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<PerformanceTableItem>;
  @Input() playerId: string;
  dataSource: PerformanceTableDataSource;
  public isEmptyTable$: Observable<Boolean>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'season',
    'age',
    'teamAbbrvName',
    'league',
    'position',
    'eFGP',
    'g',
    'gs',
    'mp',
    'fg',
    'fga',
    'fgp',
    'ft',
    'fta',
    'ftp',
    'orb',
    'drb',
    'trb',
    'ast',
    'stl',
    'blk',
    'tov',
    'pf',
    'pts',
    '3p',
    '3pa',
    '3pp',
    '2p',
    '2pa',
    '2pp'
  ];

  columnNameToHeader = {
    'season': 'Season',
    'age': 'Age',
    'teamAbbrvName': 'Team',
    'league': 'League',
    'position': 'Pos',
    'eFGP': 'EFGP',
    'g': 'G',
    'gs': 'GS',
    'mp': 'MP',
    'fg': 'FG',
    'fga': 'FGA',
    'fgp': 'FGP',
    'ft': 'FT',
    'fta': 'FTA',
    'ftp': 'FTP',
    'orb': 'ORB',
    'drb': 'DRB',
    'trb': 'TRB',
    'ast': 'AST',
    'stl': 'STL',
    'blk': 'BLK',
    'tov': 'TOV',
    'pf': 'PF',
    'pts': 'PTS',
    '3p': '3P',
    '3pa': '3PA',
    '3pp': '3PP',
    '2p': '2P',
    '2pa': '2PA',
    '2pp': '2PP'
  };

  formatTableItem(value: string | number): string {
    switch (typeof(value)) {
      case "string": return value;
      case "number": return formatNumber(value, "en-US", "0.0-2");
    }
  }
  constructor(
    private dbService: DbService
  ) { }

  ngOnInit() {
    this.dataSource = new PerformanceTableDataSource(this.playerId, this.dbService);
    this.isEmptyTable$ = this.dataSource.dataLength$.pipe(
      map(dataLength => dataLength == 0)
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
