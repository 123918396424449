<div [style.visibility]="(isEmptyTable$ | async) ? 'hidden' : 'visible'">
    <div class="perf-container">
        <table mat-table class="full-width-table" matSort aria-label="Elements">

            <ng-container *ngFor="let colName of displayedColumns" [matColumnDef]="colName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{columnNameToHeader[colName]}}</th>
                <td mat-cell *matCellDef="let row">{{formatTableItem(row[colName])}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator #paginator [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
</div>

<div *ngIf="isEmptyTable$ | async">
    No Data
</div>