<div [style.visibility]="(isEmptyTable$ | async) ? 'hidden' : 'visible'">
    <table mat-table class="mat-app-background w-100 p-1" matSort aria-label="Elements">
        <!-- Name Column -->
        <ng-container matColumnDef="team_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class='mat-h3'>Team Name</th>
            <td mat-cell *matCellDef="let row" class="mat-primary">{{row.teamName}}</td>
        </ng-container>

        <!-- Full Name Column -->
        <ng-container matColumnDef="team_abbrv_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Abbrv.</th>
            <td mat-cell *matCellDef="let row">{{row.teamAbbrvName}}</td>
        </ng-container>

        <ng-container matColumnDef="season">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Season</th>
            <td mat-cell *matCellDef="let row">
                {{row.season}}
            </td>
        </ng-container>

        <tr mat-header-row class="mat-primary" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[10, 20, 50]">
    </mat-paginator>
</div>

<div *ngIf="isEmptyTable$ | async">
    No Data
</div>