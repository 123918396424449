<div class="basic-container mat-app-background container">

    <h1 class="center mat-display-1">
        Basketball Players
    </h1>
    

    <div flex="row" class="display-players">

        <mat-card class="mat-elevation-z4 player-card" *ngFor="let player of displayPlayers">
            <mat-card-header>
                <mat-card-title>
                    {{ player }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content> 
                <p>Just some place holders </p>
            </mat-card-content>
        </mat-card>

    </div>

</div>