import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DbService } from '../db.service';
import { TeamTableDataSource, TeamTableItem } from './team-table-datasource';

@Component({
  selector: 'app-team-table',
  templateUrl: './team-table.component.html',
  styleUrls: ['./team-table.component.css']
})
export class TeamTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<TeamTableItem>;
  @Input() playerId: string;
  dataSource: TeamTableDataSource;
  
  public isEmptyTable$: Observable<Boolean>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['team_name', 'team_abbrv_name', 'season'];

  constructor(
    private dbService: DbService
  ) { }

  ngOnInit() {
    this.dataSource = new TeamTableDataSource(this.playerId, this.dbService);
    this.isEmptyTable$ = this.dataSource.dataLength$.pipe(
      map(dataLength => dataLength == 0)
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
