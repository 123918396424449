import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject } from 'rxjs';
import { SimilarPlayerRecord } from '../player';
import { DbService } from '../db.service';

// TODO: Replace this with your own data model type
export interface SimilarPlayersTableItem extends SimilarPlayerRecord {

}

const  colNames = ['playerId',
'playerName',
'dateOfBirth',
'placeOfBirth',
'similarity',
'maxSingleStatDistance'];

/**
 * Data source for the SimilarPlayersTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class SimilarPlayersTableDataSource extends DataSource<SimilarPlayersTableItem> {
  private dataSubject = new BehaviorSubject<SimilarPlayerRecord[]>([]);
  public data$ = this.dataSubject.asObservable(); 
  public dataLength$: Observable<number> = this.data$.pipe(map(data => data.length));
  paginator: MatPaginator;
  sort: MatSort;


  constructor(
    private playerId: string,
    private dbService: DbService) {
    super();
    this.downloadData();
  }

  downloadData() {
    this.dbService.getSimilarPlayers(this.playerId).subscribe(
        data => {
          this.dataSubject.next(data);
        }
      )
  
    }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<SimilarPlayersTableItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.data$,
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      let data = this.dataSubject.getValue();
      this.paginator.length = data.length;
      return this.getPagedData(this.getSortedData([...data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.dataSubject.complete();
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: SimilarPlayersTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: SimilarPlayersTableItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      if (colNames.includes(this.sort.active)) {
        let colName = this.sort.active;
        return compare(a[colName], b[colName], isAsc);
      } else {
        return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number | Date, b: string | number | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
