import { Pipe, PipeTransform } from '@angular/core';
import { unwrapPlayerId } from './player';

@Pipe({
  name: 'shortPlayerId'
})
export class ShortPlayerIdPipe implements PipeTransform {

  transform(value: string): string {
    return unwrapPlayerId(value);
  }

}
