
export class PlayerBasicRecord {
    public recordId?: string;
    public playerId: string;
    public playerUrl: string;
    public playerName: string;    
    public playerFullName: string;
    public dateOfBirth: Date;   
    public placeOfBirth: string;
    public height: number;
    public weight: number;
    public dominantHand: string;
    public college: string;      
    public highSchool: string;  
    public wikidataId: string;
    public playerEspnUrl: string;
}


export class PlayerPerformanceStat {

    public recordId?: string;
    public playerId: string;
    public season: string;
    public age: number;
    public teamAbbrvName: string;
    public league: string;
    public position: string;
    public G: number;
    public GS: number;
    public MP: number;
    public FG: number;
    public FGA: number;
    public FGP: number;
    public ThreeP: number;
    public ThreePA: number;
    public ThreePP: number;
    public TwoP: number;
    public TwoPA: number;
    public TwoPP: number;
    public eFGP: number;
    public FT: number;
    public FTA: number;
    public FTP: number;
    public ORB: number;
    public DRB: number;
    public TRB: number;
    public AST: number;
    public STL: number;
    public BLK: number;
    public TOV: number;
    public PF: number;
    public PTS: number;
}
export class ServedTeamRecord {
    public playerID: string;
    public playerName: string;
    public teamName: string; // OK
    public teamAbbrvName: string; // OK 
    public season: string; // OK
}
export class SimilarPlayerRecord {
    public recordId?: string;
    public playerId: string;
    public playerName: string;    
    public dateOfBirth: Date;   
    public placeOfBirth: string;
    public simlarity: number;
    public maxSingleStatDistance: number;
}

export class PlayerCompactRecord {
    public recordId?: string;
    public playerId: string;
    public playerName: string; 
    public playerFullName: string;   
    public dateOfBirth: Date;   
    public placeOfBirth: string;
    public seasons?: string;
}

export class TeammateRecord {
    public recordId?: string;
    public playerId: string;
    public playerName: string;    
    public dateOfBirth: Date;   
    public placeOfBirth: string;
    public season: string;
    public atTeam: string;
}

export class AlumniRecord {
    public recordId?: string;
    public playerId: string;
    public playerName: string;    
    public dateOfBirth: Date;   
    public placeOfBirth: string;
    public seasons?: string;
}

export class CoachRecord {
    public coachId: string;
    public coachName: string;
    public seasons: string;
    public coachTitle: string;
}

export function unwrapPlayerId(longPlayerId: string): string {
    return longPlayerId.split("/")[3].split(".")[0];
}
export class PlayerUtility {

}