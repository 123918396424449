<div class="row mt-5" [style.display]="(isEmptyTable$ | async) ? 'none' : 'contents'">
    <div class="col-8 m-auto">
        <div class="container-fluid p-0 info-card">
            <h1 class="row mat-display-1 p-0 m-0">
                {{ schoolKind == 'highSchool' ? 'High School Alumni' : 'College School Alumni'}}
            </h1>
            <div class="row p-0 m-0 mt-5">
                <div class="m-auto pt-2 flex-grow-1">
                    <div>
                        <table mat-table class="full-width-table" matSort aria-label="Elements">

                            <ng-container matColumnDef="playerName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                                <td mat-cell *matCellDef="let row" class="mat-primary">
                                    <a href="nba/player/{{row.playerId | shortPlayerId }}">{{row.playerName}}</a>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="dateOfBirth">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Birth </th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.dateOfBirth | date: 'yyyy-MM-dd'}}
                                    <!-- {{row.dateOfBirth}} -->
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="placeOfBirth">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Place of Birth </th>
                                <td mat-cell *matCellDef="let row"> {{row.placeOfBirth}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div *ngIf="">
    No Data
</div>