<div [style.visibility]="(isEmptyTable$ | async) ? 'hidden' : 'visible'">
    <table mat-table class="full-width-table p-1" matSort aria-label="Elements">
        <!-- Name Column -->
        <ng-container matColumnDef="playerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class='mat-h3'>Name</th>
            <td mat-cell *matCellDef="let row" class="mat-primary">
                <a href="nba/player/{{row.playerId | shortPlayerId }}">{{row.playerName}}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Birth </th>
            <td mat-cell *matCellDef="let row">
                {{row.dateOfBirth | date: 'yyyy-MM-dd'}}
                <!-- {{row.dateOfBirth}} -->
            </td>
        </ng-container>

        <ng-container matColumnDef="placeOfBirth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Place of Birth </th>
            <td mat-cell *matCellDef="let row"> {{row.placeOfBirth}} </td>
        </ng-container>

        <ng-container matColumnDef="season">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Season </th>
            <td mat-cell *matCellDef="let row"> {{row.season}} </td>
        </ng-container>

        <ng-container matColumnDef="atTeam">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Team </th>
            <td mat-cell *matCellDef="let row"> {{row.atTeam}} </td>
        </ng-container>


        <tr mat-header-row class="mat-primary" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator [pageIndex]="0" [pageSize]="25" [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
</div>

<div *ngIf="isEmptyTable$ | async">
    No Data
</div>