<div>

    <table mat-table class="mat-app-background w-100 p-1" matSort aria-label="Elements">
        <!-- Name Column -->
        <ng-container matColumnDef="player_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class='mat-h3'>Name</th>
            <td mat-cell *matCellDef="let row" class="mat-primary">
                <a href="nba/player/{{row.playerId | shortPlayerId }}">{{row.playerName}}</a>
            </td>
        </ng-container>

        <!-- Full Name Column -->
        <ng-container matColumnDef="player_full_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Full Name</th>
            <td mat-cell *matCellDef="let row">{{row.playerFullName}}</td>
        </ng-container>

        <ng-container matColumnDef="date_of_birth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Birth </th>
            <td mat-cell *matCellDef="let row">
                {{row.dateOfBirth | date: 'yyyy-MM-dd'}}
                <!-- {{row.dateOfBirth}} -->
            </td>
        </ng-container>

        <ng-container matColumnDef="place_of_birth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Place of Birth </th>
            <td mat-cell *matCellDef="let row"> {{row.placeOfBirth}} </td>
        </ng-container>
        <tr mat-header-row class="mat-primary" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
</div>