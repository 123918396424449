<div class="container-fluid full-width player-banner title-header">
    <div class="row justify-content-center mat-primary full-width pt-3 m-auto ">
        <div class="col-12 m-auto pt-5 pb-1 mat-background-primary text-center banner-title">
            <div class="h1 banner-title text-center">
                Interesting Mining in NBA Universe Based on Knowledge Graph
            </div>
            <div class="search-bar-row row">
                <div class="col-12">
                    <input class="my-3 form-control-lg w-50 search-box" placeholder="&nbsp;Search your favourite player here" [formControl]="searchFormControl">
                </div>
                <div class="col-12 button-bar">
                    <button mat-stroked-button (click)="startFilter()" class="mx-2 search-btn">Search</button>
                    <button mat-stroked-button (click)="clearFilter()" class="mx-2 search-btn">Clear</button>
                </div>
            </div>
        </div>
    </div>
    <!-- 
    <div class="search-area container-fluid justify-content-center">
        <div class="row">
            <div class="col-8 m-auto">
                <mat-form-field appearance="outline">
                    <mat-label>Outline form field</mat-label>
                    <input matInput placeholder="Placeholder">
                    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                    <mat-hint>Hint</mat-hint>
                </mat-form-field>
            </div>

        </div>
        <div class="row pt-3">
            <input class="col-2 m-auto" type="button" value="search">
        </div>

    </div> -->

</div>

<div class="h1 title-header-spacer"></div>

<div class="container-fluid">
    <h1 class="row bold mat-display-1 mt-2 mb-3">
        <div class="col-8 mx-auto p-0">
            <p>{{isSearching ? "Search Results" : "Player List"}}</p>
        </div>
    </h1>
    <div class="row mt-3 mb-3">
        <div class="col-8 mx-auto">
            <app-player-table></app-player-table>
        </div>
    </div>
</div>