export const SIMILAR_PLAYERS = {
    "total": 6,
    "msg": "success",
    "rows": [
      {
        "playerId": "/players/r/roybr01.html",
        "playerName": "Brandon Roy",
        "dateOfBirth": "1984-07-23",
        "placeOfBirth": "Seattle, Washington",
        "similarity": 0.9991477692305674,
        "maxSingleStatDistance": 3.1566666666666663
      },
      {
        "playerId": "/players/c/cartevi01.html",
        "playerName": "Vince Carter",
        "dateOfBirth": "1977-01-26",
        "placeOfBirth": "Daytona Beach, Florida",
        "similarity": 0.9982871434928426,
        "maxSingleStatDistance": 2.5033333333333303
      },
      {
        "playerId": "/players/r/reddmi01.html",
        "playerName": "Michael Redd",
        "dateOfBirth": "1979-08-24",
        "placeOfBirth": "Columbus, Ohio",
        "similarity": 0.994787922723492,
        "maxSingleStatDistance": 4.289999999999997
      },
      {
        "playerId": "/players/s/stackje01.html",
        "playerName": "Jerry Stackhouse",
        "dateOfBirth": "1974-11-05",
        "placeOfBirth": "Kinston, North Carolina",
        "similarity": 0.9943790617278868,
        "maxSingleStatDistance": 4.25923076923077
      },
      {
        "playerId": "/players/r/richaja01.html",
        "playerName": "Jason Richardson",
        "dateOfBirth": "1981-01-20",
        "placeOfBirth": "Saginaw, Michigan",
        "similarity": 0.9902550044812657,
        "maxSingleStatDistance": 3.660000000000002
      },
      {
        "playerId": "/players/m/martike02.html",
        "playerName": "Kevin Martin",
        "dateOfBirth": "1983-02-01",
        "placeOfBirth": "Zanesville, Ohio",
        "similarity": 0.9852869006719183,
        "maxSingleStatDistance": 4.252307692307694
      }
    ]
  };
