import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { DbService } from '../db.service';
import { CoachesTableDataSource, CoachesTableItem } from './coaches-table-datasource';

@Component({
  selector: 'app-coaches-table',
  templateUrl: './coaches-table.component.html',
  styleUrls: ['./coaches-table.component.css']
})
export class CoachesTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<CoachesTableItem>;
  @Input() playerId: string;
  dataSource: CoachesTableDataSource;
  public isEmptyTable$: Observable<Boolean>;

  constructor (
    private dbService: DbService
  ) { }
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['coachName', 'coachTitle', 'season', 'atTeam'];

  ngOnInit() {
    this.dataSource = new CoachesTableDataSource(this.playerId, this.dbService);
    this.isEmptyTable$ = this.dataSource.dataLength$.pipe(
      map(dataLength => dataLength == 0)
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
