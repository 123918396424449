export const PLAYER_PERFORMANCE_STAT: Object = {
    "total": 15,
    "msg": "success",
    "rows": [
      {
        "recordId": "fd39e3c6-1a32-11eb-b35d-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2019-20",
        "age": 35,
        "teamAbbrvName": "LAL",
        "league": "NBA",
        "position": "PG",
        "eFGP": 0.55,
        "g": 67,
        "gs": 67,
        "mp": 34.6,
        "fg": 9.6,
        "fga": 19.4,
        "fgp": 0.493,
        "ft": 3.9,
        "fta": 5.7,
        "ftp": 0.693,
        "orb": 1.0,
        "drb": 6.9,
        "trb": 7.8,
        "ast": 10.2,
        "stl": 1.2,
        "blk": 0.5,
        "tov": 3.9,
        "pf": 1.8,
        "pts": 25.3,
        "3p": 2.2,
        "3pa": 6.3,
        "3pp": 0.348,
        "2p": 7.4,
        "2pa": 13.1,
        "2pp": 0.564
      },
      {
        "recordId": "fd378b1a-1a32-11eb-be84-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2018-19",
        "age": 34,
        "teamAbbrvName": "LAL",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.56,
        "g": 55,
        "gs": 55,
        "mp": 35.2,
        "fg": 10.1,
        "fga": 19.9,
        "fgp": 0.51,
        "ft": 5.1,
        "fta": 7.6,
        "ftp": 0.665,
        "orb": 1.0,
        "drb": 7.4,
        "trb": 8.5,
        "ast": 8.3,
        "stl": 1.3,
        "blk": 0.6,
        "tov": 3.6,
        "pf": 1.7,
        "pts": 27.4,
        "3p": 2.0,
        "3pa": 5.9,
        "3pp": 0.339,
        "2p": 8.1,
        "2pa": 14.0,
        "2pp": 0.582
      },
      {
        "recordId": "fd354008-1a32-11eb-949c-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2017-18",
        "age": 33,
        "teamAbbrvName": "CLE",
        "league": "NBA",
        "position": "PF",
        "eFGP": 0.59,
        "g": 82,
        "gs": 82,
        "mp": 36.9,
        "fg": 10.5,
        "fga": 19.3,
        "fgp": 0.542,
        "ft": 4.7,
        "fta": 6.5,
        "ftp": 0.731,
        "orb": 1.2,
        "drb": 7.5,
        "trb": 8.6,
        "ast": 9.1,
        "stl": 1.4,
        "blk": 0.9,
        "tov": 4.2,
        "pf": 1.7,
        "pts": 27.5,
        "3p": 1.8,
        "3pa": 5.0,
        "3pp": 0.367,
        "2p": 8.6,
        "2pa": 14.3,
        "2pp": 0.603
      },
      {
        "recordId": "fd32e428-1a32-11eb-8cd5-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2016-17",
        "age": 32,
        "teamAbbrvName": "CLE",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.594,
        "g": 74,
        "gs": 74,
        "mp": 37.8,
        "fg": 9.9,
        "fga": 18.2,
        "fgp": 0.548,
        "ft": 4.8,
        "fta": 7.2,
        "ftp": 0.674,
        "orb": 1.3,
        "drb": 7.3,
        "trb": 8.6,
        "ast": 8.7,
        "stl": 1.2,
        "blk": 0.6,
        "tov": 4.1,
        "pf": 1.8,
        "pts": 26.4,
        "3p": 1.7,
        "3pa": 4.6,
        "3pp": 0.363,
        "2p": 8.3,
        "2pa": 13.5,
        "2pp": 0.611
      },
      {
        "recordId": "fd308ba8-1a32-11eb-90e7-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2015-16",
        "age": 31,
        "teamAbbrvName": "CLE",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.551,
        "g": 76,
        "gs": 76,
        "mp": 35.6,
        "fg": 9.7,
        "fga": 18.6,
        "fgp": 0.52,
        "ft": 4.7,
        "fta": 6.5,
        "ftp": 0.731,
        "orb": 1.5,
        "drb": 6.0,
        "trb": 7.4,
        "ast": 6.8,
        "stl": 1.4,
        "blk": 0.6,
        "tov": 3.3,
        "pf": 1.9,
        "pts": 25.3,
        "3p": 1.1,
        "3pa": 3.7,
        "3pp": 0.309,
        "2p": 8.6,
        "2pa": 14.9,
        "2pp": 0.573
      },
      {
        "recordId": "fd2e21ba-1a32-11eb-90a6-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2014-15",
        "age": 30,
        "teamAbbrvName": "CLE",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.535,
        "g": 69,
        "gs": 69,
        "mp": 36.1,
        "fg": 9.0,
        "fga": 18.5,
        "fgp": 0.488,
        "ft": 5.4,
        "fta": 7.7,
        "ftp": 0.71,
        "orb": 0.7,
        "drb": 5.3,
        "trb": 6.0,
        "ast": 7.4,
        "stl": 1.6,
        "blk": 0.7,
        "tov": 3.9,
        "pf": 2.0,
        "pts": 25.3,
        "3p": 1.7,
        "3pa": 4.9,
        "3pp": 0.354,
        "2p": 7.3,
        "2pa": 13.6,
        "2pp": 0.536
      },
      {
        "recordId": "fd2bd1a8-1a32-11eb-987a-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2013-14",
        "age": 29,
        "teamAbbrvName": "MIA",
        "league": "NBA",
        "position": "PF",
        "eFGP": 0.61,
        "g": 77,
        "gs": 77,
        "mp": 37.7,
        "fg": 10.0,
        "fga": 17.6,
        "fgp": 0.567,
        "ft": 5.7,
        "fta": 7.6,
        "ftp": 0.75,
        "orb": 1.1,
        "drb": 5.9,
        "trb": 6.9,
        "ast": 6.3,
        "stl": 1.6,
        "blk": 0.3,
        "tov": 3.5,
        "pf": 1.6,
        "pts": 27.1,
        "3p": 1.5,
        "3pa": 4.0,
        "3pp": 0.379,
        "2p": 8.5,
        "2pa": 13.6,
        "2pp": 0.622
      },
      {
        "recordId": "fd29699a-1a32-11eb-bf0a-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2012-13",
        "age": 28,
        "teamAbbrvName": "MIA",
        "league": "NBA",
        "position": "PF",
        "eFGP": 0.603,
        "g": 76,
        "gs": 76,
        "mp": 37.9,
        "fg": 10.1,
        "fga": 17.8,
        "fgp": 0.565,
        "ft": 5.3,
        "fta": 7.0,
        "ftp": 0.753,
        "orb": 1.3,
        "drb": 6.8,
        "trb": 8.0,
        "ast": 7.3,
        "stl": 1.7,
        "blk": 0.9,
        "tov": 3.0,
        "pf": 1.4,
        "pts": 26.8,
        "3p": 1.4,
        "3pa": 3.3,
        "3pp": 0.406,
        "2p": 8.7,
        "2pa": 14.5,
        "2pp": 0.602
      },
      {
        "recordId": "fd270f42-1a32-11eb-9850-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2011-12",
        "age": 27,
        "teamAbbrvName": "MIA",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.554,
        "g": 62,
        "gs": 62,
        "mp": 37.5,
        "fg": 10.0,
        "fga": 18.9,
        "fgp": 0.531,
        "ft": 6.2,
        "fta": 8.1,
        "ftp": 0.771,
        "orb": 1.5,
        "drb": 6.4,
        "trb": 7.9,
        "ast": 6.2,
        "stl": 1.9,
        "blk": 0.8,
        "tov": 3.4,
        "pf": 1.5,
        "pts": 27.1,
        "3p": 0.9,
        "3pa": 2.4,
        "3pp": 0.362,
        "2p": 9.1,
        "2pa": 16.5,
        "2pp": 0.556
      },
      {
        "recordId": "fd24bbe8-1a32-11eb-8193-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2010-11",
        "age": 26,
        "teamAbbrvName": "MIA",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.541,
        "g": 79,
        "gs": 79,
        "mp": 38.8,
        "fg": 9.6,
        "fga": 18.8,
        "fgp": 0.51,
        "ft": 6.4,
        "fta": 8.4,
        "ftp": 0.759,
        "orb": 1.0,
        "drb": 6.5,
        "trb": 7.5,
        "ast": 7.0,
        "stl": 1.6,
        "blk": 0.6,
        "tov": 3.6,
        "pf": 2.1,
        "pts": 26.7,
        "3p": 1.2,
        "3pa": 3.5,
        "3pp": 0.33,
        "2p": 8.4,
        "2pa": 15.3,
        "2pp": 0.552
      },
      {
        "recordId": "fd225b50-1a32-11eb-9a3f-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2009-10",
        "age": 25,
        "teamAbbrvName": "CLE",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.545,
        "g": 76,
        "gs": 76,
        "mp": 39.0,
        "fg": 10.1,
        "fga": 20.1,
        "fgp": 0.503,
        "ft": 7.8,
        "fta": 10.2,
        "ftp": 0.767,
        "orb": 0.9,
        "drb": 6.4,
        "trb": 7.3,
        "ast": 8.6,
        "stl": 1.6,
        "blk": 1.0,
        "tov": 3.4,
        "pf": 1.6,
        "pts": 29.7,
        "3p": 1.7,
        "3pa": 5.1,
        "3pp": 0.333,
        "2p": 8.4,
        "2pa": 15.0,
        "2pp": 0.56
      },
      {
        "recordId": "fd200026-1a32-11eb-8af9-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2008-09",
        "age": 24,
        "teamAbbrvName": "CLE",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.53,
        "g": 81,
        "gs": 81,
        "mp": 37.7,
        "fg": 9.7,
        "fga": 19.9,
        "fgp": 0.489,
        "ft": 7.3,
        "fta": 9.4,
        "ftp": 0.78,
        "orb": 1.3,
        "drb": 6.3,
        "trb": 7.6,
        "ast": 7.2,
        "stl": 1.7,
        "blk": 1.1,
        "tov": 3.0,
        "pf": 1.7,
        "pts": 28.4,
        "3p": 1.6,
        "3pa": 4.7,
        "3pp": 0.344,
        "2p": 8.1,
        "2pa": 15.2,
        "2pp": 0.535
      },
      {
        "recordId": "fd1db0fa-1a32-11eb-b89d-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2007-08",
        "age": 23,
        "teamAbbrvName": "CLE",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.518,
        "g": 75,
        "gs": 74,
        "mp": 40.4,
        "fg": 10.6,
        "fga": 21.9,
        "fgp": 0.484,
        "ft": 7.3,
        "fta": 10.3,
        "ftp": 0.712,
        "orb": 1.8,
        "drb": 6.1,
        "trb": 7.9,
        "ast": 7.2,
        "stl": 1.8,
        "blk": 1.1,
        "tov": 3.4,
        "pf": 2.2,
        "pts": 30.0,
        "3p": 1.5,
        "3pa": 4.8,
        "3pp": 0.315,
        "2p": 9.1,
        "2pa": 17.1,
        "2pp": 0.531
      },
      {
        "recordId": "fd1b4574-1a32-11eb-aade-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2006-07",
        "age": 22,
        "teamAbbrvName": "CLE",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.507,
        "g": 78,
        "gs": 78,
        "mp": 40.9,
        "fg": 9.9,
        "fga": 20.8,
        "fgp": 0.476,
        "ft": 6.3,
        "fta": 9.0,
        "ftp": 0.698,
        "orb": 1.1,
        "drb": 5.7,
        "trb": 6.7,
        "ast": 6.0,
        "stl": 1.6,
        "blk": 0.7,
        "tov": 3.2,
        "pf": 2.2,
        "pts": 27.3,
        "3p": 1.3,
        "3pa": 4.0,
        "3pp": 0.319,
        "2p": 8.6,
        "2pa": 16.8,
        "2pp": 0.513
      },
      {
        "recordId": "fd18d186-1a32-11eb-8ae6-acde48001122",
        "playerId": "/players/j/jamesle01.html",
        "season": "2005-06",
        "age": 21,
        "teamAbbrvName": "CLE",
        "league": "NBA",
        "position": "SF",
        "eFGP": 0.515,
        "g": 79,
        "gs": 79,
        "mp": 42.5,
        "fg": 11.1,
        "fga": 23.1,
        "fgp": 0.48,
        "ft": 7.6,
        "fta": 10.3,
        "ftp": 0.738,
        "orb": 0.9,
        "drb": 6.1,
        "trb": 7.0,
        "ast": 6.6,
        "stl": 1.6,
        "blk": 0.8,
        "tov": 3.3,
        "pf": 2.3,
        "pts": 31.4,
        "3p": 1.6,
        "3pa": 4.8,
        "3pp": 0.335,
        "2p": 9.5,
        "2pa": 18.3,
        "2pp": 0.518
      }
    ]
  };  