import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Observable } from 'rxjs';
import { DbService } from '../db.service';
import { PlayerTableDataSource, PlayerTableItem } from './player-table-datasource';

@Component({
  selector: 'app-player-table',
  templateUrl: './player-table.component.html',
  styleUrls: ['./player-table.component.css']
})
export class PlayerTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<PlayerTableItem>;

  formGroup: FormGroup;
  filterFormContorl = new FormControl('');
  dataSource: PlayerTableDataSource = null;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['player_name', 'player_full_name', 'date_of_birth', 'place_of_birth'];

  public startFilter() {
    console.log(`Current filter: ${this.filterFormContorl.value}`);

    this.dataSource.updateFilter(this.filterFormContorl.value);
  }
  
  setFilterAndSearch(filter: string) {
    console.log(`current filter: ${filter}`);
    this.dataSource.updateFilter(filter);
  }

  constructor (
    private dbService: DbService,
    private formBuilder: FormBuilder
  ) { 

    // this.formGroup = formBuilder.group([ {'filter': ['']}])

    
    this.dataSource = new PlayerTableDataSource(dbService);
    this.dataSource.downloadData();

    
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }


}
