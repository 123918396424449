import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DbService } from '../db.service';
import { AlumniTableDataSource, AlumniTableItem } from './alumni-table-datasource';

@Component({
  selector: 'app-alumni-table',
  templateUrl: './alumni-table.component.html',
  styleUrls: ['./alumni-table.component.css']
})
export class AlumniTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<AlumniTableItem>;
  @Input() playerId: string;
  @Input() schoolKind: string;
  dataSource: AlumniTableDataSource;
  isEmptyTable$: Observable<Boolean>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns =  ['playerName', 'dateOfBirth', 'placeOfBirth'];

  constructor (
    private dbService: DbService
  ) { }

  ngOnInit() {
    this.dataSource = new AlumniTableDataSource(this.playerId, this.schoolKind, this.dbService);
    this.isEmptyTable$ = this.dataSource.dataLength$.pipe(map(l => l == 0));
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
