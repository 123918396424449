import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DbService } from './db.service';

const API_HEADER: string = (
  // "https://www.rogerlin.tech/nba/api/"
  "/nba/api/"
);

@Injectable({
  providedIn: 'root'
})
export class DbHttpService extends DbService {

  getLongPlayerId(playerId: string) {
    if (playerId.length == 0) {
      return "";
    }
    let longId = "/players/" + playerId.charAt(0) + "/" + playerId;
    return encodeURIComponent(longId);
  }

  getUrl(service: string, playerId: string) {
    return API_HEADER + service + "?playerId=" + this.getLongPlayerId(playerId) + ".html";
  }

  getUrlWithLongPlayerId(service: string, playerId: string) {
    return API_HEADER + service + "?playerId=" + encodeURIComponent(playerId);
  }

  fetchPlayerBasicFromBackend(shortPlayerId:string): Observable<Object> {
    return this.http.get(this.getUrl('fetchPlayerBasic', shortPlayerId));
  }

  fetchPlayerServedTeamsFromBackend(playerId:string): Observable<Object> {
    return this.http.get(this.getUrlWithLongPlayerId('fetchPlayerServedTeam', playerId));
  }

  fetchPlayerPerformanceStat(playerId:string): Observable<Object> {
    return this.http.get(this.getUrlWithLongPlayerId('fetchPlayerPerfStatPerGame', playerId) + "&limit=0&offset=0");
  }
  
  fetchSimilarPlayres(playerId: string): Observable<Object> {
    return this.http.get(this.getUrlWithLongPlayerId('fetchSimilarPlayers', playerId));
  }

  fetchTeammates(playerId: string): Observable<Object> {
    return this.http.get(this.getUrlWithLongPlayerId('fetchPlayerTeammates', playerId) 
    + "&order=asc&sort=season");
  }

  fetchCoaches(playerId: string): Observable<Object> {
    return this.http.get(this.getUrlWithLongPlayerId('fetchCoWorkedCoaches', playerId));
  }

  fetchPlayerList(): Observable<Object> {
    return this.http.get(API_HEADER + "fetchPlayerBasicCompact?sort=player_name&order=asc&limit=0&offset=0");
  }

  fetchCollegeAlumni(playerId: string): Observable<Object> {
    return this.http.get(this.getUrlWithLongPlayerId('fetchPlayerAlumni', playerId) + "&alumniType=College");
  }

  fetchHighSchoolAlumni(playerId: string): Observable<Object> {
    return this.http.get(this.getUrlWithLongPlayerId('fetchPlayerAlumni', playerId) + "&alumniType=HighSchool");
  }
  constructor(
    private http: HttpClient
  ) {
    super();

  }
}
