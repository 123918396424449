export const ALUMNI: Object = {
    "total": 3,
    "msg": "success",
    "rows": [
      {
        "playerId": "/players/d/daniema01.html",
        "playerName": "Marquis Daniels",
        "dateOfBirth": "1981-01-07",
        "placeOfBirth": "Jasper, Florida"
      },
      {
        "playerId": "/players/r/rushbr01.html",
        "playerName": "Brandon Rush",
        "dateOfBirth": "1985-07-07",
        "placeOfBirth": "Kansas City, Missouri"
      },
      {
        "playerId": "/players/e/earlycl01.html",
        "playerName": "Cleanthony Early",
        "dateOfBirth": "1991-04-17",
        "placeOfBirth": "Middletown, New York"
      }
    ]
  };