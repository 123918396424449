export const PLAYER_BASIC_LIST_COMPACT: Object = {
    "total": 4803,
    "msg": "success",
    "totalNotFiltered": 4803,
    "rows": [
      {
        "recordId": "71f34390-1a6a-11eb-8d6c-3c6aa7e0cfbd",
        "playerId": "/players/g/greenac01.html",
        "playerName": "A.C. Green",
        "playerFullName": "A.C. Green Jr.",
        "dateOfBirth": "1963-10-04",
        "placeOfBirth": "Portland, Oregon"
      },
      {
        "recordId": "35ffb2b4-1a6b-11eb-a67c-3c6aa7e0cfbd",
        "playerId": "/players/b/bramlaj01.html",
        "playerName": "A.J. Bramlett",
        "playerFullName": "Aaron Jordan Bramlett",
        "dateOfBirth": "1977-01-10",
        "placeOfBirth": "Dekalb, Illinois"
      },
      {
        "recordId": "2922f3fc-1a6c-11eb-a67c-3c6aa7e0cfbd",
        "playerId": "/players/e/engliaj01.html",
        "playerName": "A.J. English",
        "playerFullName": "Albert Jay English",
        "dateOfBirth": "1967-07-11",
        "placeOfBirth": "Wilmington, Delaware"
      },
      {
        "recordId": "41d4466e-1a6a-11eb-8d6c-3c6aa7e0cfbd",
        "playerId": "/players/g/guytoaj01.html",
        "playerName": "A.J. Guyton",
        "playerFullName": "Arthur James Guyton",
        "dateOfBirth": "1978-02-12",
        "placeOfBirth": "Peoria, Illinois"
      },
      {
        "recordId": "e36690ac-1a67-11eb-afac-3c6aa7e0cfbd",
        "playerId": "/players/h/hammoaj01.html",
        "playerName": "A.J. Hammons",
        "playerFullName": "Aaron Jarrell Hammons",
        "dateOfBirth": "1992-08-27",
        "placeOfBirth": "Gary, Indiana"
      },
      {
        "recordId": "25535568-1a68-11eb-afac-3c6aa7e0cfbd",
        "playerId": "/players/p/priceaj01.html",
        "playerName": "A.J. Price",
        "playerFullName": "Anthony Jordan Price",
        "dateOfBirth": "1986-10-07",
        "placeOfBirth": "Orange, New Jersey"
      },
      {
        "recordId": "4767407a-1a6c-11eb-a67c-3c6aa7e0cfbd",
        "playerId": "/players/w/wyndeaj01.html",
        "playerName": "A.J. Wynder",
        "playerFullName": "A.J. Wynder",
        "dateOfBirth": "1964-09-11",
        "placeOfBirth": "Bronx, New York"
      },
      {
        "recordId": "61e9bfb4-1a70-11eb-ae1d-3c6aa7e0cfbd",
        "playerId": "/players/h/holtaw01.html",
        "playerName": "A.W. Holt",
        "playerFullName": "Alvin William Holt",
        "dateOfBirth": "1946-08-26",
        "placeOfBirth": null
      },
      {
        "recordId": "88cb48cc-1a67-11eb-afac-3c6aa7e0cfbd",
        "playerId": "/players/b/brookaa01.html",
        "playerName": "Aaron Brooks",
        "playerFullName": "Aaron Jamal Brooks",
        "dateOfBirth": "1985-01-14",
        "placeOfBirth": "Seattle, Washington"
      },
      {
        "recordId": "5fa42f18-1a35-11eb-8cb6-acde48001122",
        "playerId": "/players/g/gordoaa01.html",
        "playerName": "Aaron Gordon",
        "playerFullName": "Aaron Addison Gordon",
        "dateOfBirth": "1995-09-16",
        "placeOfBirth": "San Jose, California"
      },
      {
        "recordId": "4e0307ec-1a68-11eb-afac-3c6aa7e0cfbd",
        "playerId": "/players/g/grayaa01.html",
        "playerName": "Aaron Gray",
        "playerFullName": "Aaron Michael Gray",
        "dateOfBirth": "1984-12-07",
        "placeOfBirth": "Tarzana, California"
      },
      {
        "recordId": "9e104304-1a67-11eb-afac-3c6aa7e0cfbd",
        "playerId": "/players/h/harriaa01.html",
        "playerName": "Aaron Harrison",
        "playerFullName": "Aaron Malik Harrison",
        "dateOfBirth": "1994-10-28",
        "placeOfBirth": "San Antonio, Texas"
      },
      {
        "recordId": "6dbd809e-1a36-11eb-9d76-acde48001122",
        "playerId": "/players/h/holidaa01.html",
        "playerName": "Aaron Holiday",
        "playerFullName": "Aaron Shawn Holiday",
        "dateOfBirth": "1996-09-30",
        "placeOfBirth": "Ruston, Louisiana"
      },
      {
        "recordId": "b297e21e-1a67-11eb-afac-3c6aa7e0cfbd",
        "playerId": "/players/j/jacksaa01.html",
        "playerName": "Aaron Jackson",
        "playerFullName": "Aaron Jackson",
        "dateOfBirth": "1986-05-06",
        "placeOfBirth": "Hartford, Connecticut"
      },
      {
        "recordId": "7b8ad0a0-1a6d-11eb-a67c-3c6aa7e0cfbd",
        "playerId": "/players/j/jamesaa01.html",
        "playerName": "Aaron James",
        "playerFullName": "Aaron James",
        "dateOfBirth": "1952-10-05",
        "placeOfBirth": "New Orleans, Louisiana"
      },
      {
        "recordId": "7def75b6-1a69-11eb-9830-3c6aa7e0cfbd",
        "playerId": "/players/m/mckieaa01.html",
        "playerName": "Aaron McKie",
        "playerFullName": "Aaron Fitzgerald McKie",
        "dateOfBirth": "1972-10-02",
        "placeOfBirth": "Philadelphia, Pennsylvania"
      },
      {
        "recordId": "d45bde62-1a69-11eb-8d6c-3c6aa7e0cfbd",
        "playerId": "/players/m/milesaa01.html",
        "playerName": "Aaron Miles",
        "playerFullName": "Aaron Marquez Miles",
        "dateOfBirth": "1983-04-13",
        "placeOfBirth": "Portland, Oregon"
      },
      {
        "recordId": "d8363e9a-1a6b-11eb-a67c-3c6aa7e0cfbd",
        "playerId": "/players/s/swinsaa01.html",
        "playerName": "Aaron Swinson",
        "playerFullName": "Aaron Anthony Swinson",
        "dateOfBirth": "1971-01-09",
        "placeOfBirth": "Brunswick, Georgia"
      },
      {
        "recordId": "563ed73c-1a69-11eb-9830-3c6aa7e0cfbd",
        "playerId": "/players/w/williaa01.html",
        "playerName": "Aaron Williams",
        "playerFullName": "Aaron Williams",
        "dateOfBirth": "1971-10-02",
        "placeOfBirth": "Evanston, Illinois"
      },
      {
        "recordId": "3df6bb78-1a36-11eb-960f-acde48001122",
        "playerId": "/players/n/naderab01.html",
        "playerName": "Abdel Nader",
        "playerFullName": "Abdel Nader",
        "dateOfBirth": "1993-09-25",
        "placeOfBirth": "Alexandria, Egypt"
      },
      {
        "recordId": "4817791c-1a6d-11eb-a67c-3c6aa7e0cfbd",
        "playerId": "/players/j/jeelaab01.html",
        "playerName": "Abdul Jeelani",
        "playerFullName": "Abdul Qadir Jeelani",
        "dateOfBirth": "1954-02-10",
        "placeOfBirth": "Bells, Tennessee"
      },
      {
        "recordId": "9144f418-1a6b-11eb-a67c-3c6aa7e0cfbd",
        "playerId": "/players/e/earlac01.html",
        "playerName": "Acie Earl",
        "playerFullName": "Acie Boyd Earl",
        "dateOfBirth": "1970-06-23",
        "placeOfBirth": "Peoria, Illinois"
      },
      {
        "recordId": "0ca8f9ae-1a69-11eb-9830-3c6aa7e0cfbd",
        "playerId": "/players/l/lawac01.html",
        "playerName": "Acie Law",
        "playerFullName": "Acie Law IV",
        "dateOfBirth": "1985-01-25",
        "placeOfBirth": "Dallas, Texas"
      },
      {
        "recordId": "4c9514f2-1a6a-11eb-8d6c-3c6aa7e0cfbd",
        "playerId": "/players/h/harriad01.html",
        "playerName": "Adam Harrington",
        "playerFullName": "Adam Philip Harrington",
        "dateOfBirth": "1980-07-05",
        "placeOfBirth": "Bernardston, Massachusetts"
      },
      {
        "recordId": "870b74a0-1a6a-11eb-8d6c-3c6aa7e0cfbd",
        "playerId": "/players/k/keefead01.html",
        "playerName": "Adam Keefe",
        "playerFullName": "Adam Thomas Keefe",
        "dateOfBirth": "1970-02-22",
        "placeOfBirth": "Irvine, California"
      }
    ]
  };  