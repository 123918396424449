
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent implements OnInit {

  displayPlayers: Array<String>
  constructor() {

    this.displayPlayers = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
    ]
  }

  ngOnInit(): void {
  }

}
