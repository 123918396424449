import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DbService } from '../db.service';
import { SimilarPlayersTableDataSource, SimilarPlayersTableItem } from './similar-players-table-datasource';

@Component({
  selector: 'app-similar-players-table',
  templateUrl: './similar-players-table.component.html',
  styleUrls: ['./similar-players-table.component.css']
})
export class SimilarPlayersTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<SimilarPlayersTableItem>;
  @Input() playerId: string;
  dataSource: SimilarPlayersTableDataSource;

  public isEmptyTable$: Observable<Boolean>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
 'playerName',
 'dateOfBirth',
 'placeOfBirth',
 'similarity',
 'maxSingleStatDistance'];


  constructor (
    private dbService: DbService
  ) { }
  ngOnInit() {
    this.dataSource = new SimilarPlayersTableDataSource(this.playerId, this.dbService);
    this.isEmptyTable$ = this.dataSource.dataLength$.pipe(
      map(dataLength => dataLength == 0)
    )
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
