export const COACHES: Object = {
    "total": 90,
    "msg": "success",
    "rows": [
      {
        "coachId": "/coaches/udokaim01c.html",
        "coachName": "Ime Udoka",
        "coachTitle": "Assistant Coach",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "coachId": "/coaches/popovgr99c.html",
        "coachName": "Gregg Popovich",
        "coachTitle": "Head Coach",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "coachId": "/coaches/forcich01c.html",
        "coachName": "Chad Forcier",
        "coachTitle": "Assistant Coach",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "coachId": "/coaches/engelch01c.html",
        "coachName": "Chip Engelland",
        "coachTitle": "Assistant Coach",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "coachId": "/coaches/budenmi99c.html",
        "coachName": "Mike Budenholzer",
        "coachTitle": "Assistant Coach",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "coachId": "/coaches/brownbr99c.html",
        "coachName": "Brett Brown",
        "coachTitle": "Assistant Coach",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "coachId": "/coaches/radulpe01c.html",
        "coachName": "Pete Radulovic",
        "coachTitle": "Assistant Coach",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "coachId": "/coaches/hillty01c.html",
        "coachName": "Tyrone Hill",
        "coachTitle": "Assistant Coach",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "coachId": "/coaches/gattike01c.html",
        "coachName": "Kenny Gattison",
        "coachTitle": "Assistant Coach",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "coachId": "/coaches/drewla01c.html",
        "coachName": "Larry Drew",
        "coachTitle": "Head Coach",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "coachId": "/coaches/connele01c.html",
        "coachName": "Lester Conner",
        "coachTitle": "Assistant Coach",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "coachId": "/coaches/bendebo01c.html",
        "coachName": "Bob Bender",
        "coachTitle": "Assistant Coach",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "coachId": "/coaches/walkeda01c.html",
        "coachName": "Darrell Walker",
        "coachTitle": "Assistant Coach",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "coachId": "/coaches/sullipa01c.html",
        "coachName": "Pat Sullivan",
        "coachTitle": "Assistant Coach",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "coachId": "/coaches/popebi01c.html",
        "coachName": "Bill Pope",
        "coachTitle": "Assistant Coach",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "coachId": "/coaches/kuestjo01c.html",
        "coachName": "John Kuester",
        "coachTitle": "Head Coach",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "coachId": "/coaches/hillbr99c.html",
        "coachName": "Brian Hill",
        "coachTitle": "Assistant Coach",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "coachId": "/coaches/turneel01c.html",
        "coachName": "Elston Turner",
        "coachTitle": "Assistant Coach",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/sikmaja01c.html",
        "coachName": "Jack Sikma",
        "coachTitle": "Assistant Coach",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/dunntr01c.html",
        "coachName": "T.R. Dunn",
        "coachTitle": "Assistant Coach",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/adelmrj01c.html",
        "coachName": "R.J. Adelman",
        "coachTitle": "Assistant Coach",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/adelmri01c.html",
        "coachName": "Rick Adelman",
        "coachTitle": "Head Coach",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/willihe01c.html",
        "coachName": "Herb Williams",
        "coachTitle": "Assistant Coach",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "coachId": "/coaches/weberph01c.html",
        "coachName": "Phil Weber",
        "coachTitle": "Assistant Coach",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "coachId": "/coaches/dantomi01c.html",
        "coachName": "Mike D'Antoni",
        "coachTitle": "Head Coach",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "coachId": "/coaches/dantoda01c.html",
        "coachName": "Dan D'Antoni",
        "coachTitle": "Assistant Coach",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "coachId": "/coaches/brittgr01c.html",
        "coachName": "Greg Brittenham",
        "coachTitle": "Assistant Coach",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "coachId": "/coaches/atkinke01c.html",
        "coachName": "Kenny Atkinson",
        "coachTitle": "Assistant Coach",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "coachId": "/coaches/turneel01c.html",
        "coachName": "Elston Turner",
        "coachTitle": "Assistant Coach",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/sikmaja01c.html",
        "coachName": "Jack Sikma",
        "coachTitle": "Assistant Coach",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/dunntr01c.html",
        "coachName": "T.R. Dunn",
        "coachTitle": "Assistant Coach",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/adelmrj01c.html",
        "coachName": "R.J. Adelman",
        "coachTitle": "Assistant Coach",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/adelmri01c.html",
        "coachName": "Rick Adelman",
        "coachTitle": "Head Coach",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/turneel01c.html",
        "coachName": "Elston Turner",
        "coachTitle": "Assistant Coach",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/sikmaja01c.html",
        "coachName": "Jack Sikma",
        "coachTitle": "Assistant Coach",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/dunntr01c.html",
        "coachName": "T.R. Dunn",
        "coachTitle": "Assistant Coach",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/adelmrj01c.html",
        "coachName": "R.J. Adelman",
        "coachTitle": "Assistant Coach",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/adelmri01c.html",
        "coachName": "Rick Adelman",
        "coachTitle": "Head Coach",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/wardch01c.html",
        "coachName": "Charlie Ward",
        "coachTitle": "Assistant Coach",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/vanguje99c.html",
        "coachName": "Jeff Van Gundy",
        "coachTitle": "Head Coach",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/thiboto99c.html",
        "coachName": "Tom Thibodeau",
        "coachTitle": "Assistant Coach",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/longami01c.html",
        "coachName": "Mike Longabardi",
        "coachTitle": "Assistant Coach",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/greeran01c.html",
        "coachName": "Andy Greer",
        "coachTitle": "Assistant Coach",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/ewingpa01c.html",
        "coachName": "Patrick Ewing",
        "coachTitle": "Assistant Coach",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/cliffst99c.html",
        "coachName": "Steve Clifford",
        "coachTitle": "Assistant Coach",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/vanguje99c.html",
        "coachName": "Jeff Van Gundy",
        "coachTitle": "Head Coach",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/thiboto99c.html",
        "coachName": "Tom Thibodeau",
        "coachTitle": "Assistant Coach",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/sannji01c.html",
        "coachName": "Jim Sann",
        "coachTitle": "Assistant Coach",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/greeran01c.html",
        "coachName": "Andy Greer",
        "coachTitle": "Assistant Coach",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/ewingpa01c.html",
        "coachName": "Patrick Ewing",
        "coachTitle": "Assistant Coach",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/cliffst99c.html",
        "coachName": "Steve Clifford",
        "coachTitle": "Assistant Coach",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/vanguje99c.html",
        "coachName": "Jeff Van Gundy",
        "coachTitle": "Head Coach",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/thiboto99c.html",
        "coachName": "Tom Thibodeau",
        "coachTitle": "Assistant Coach",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/greeran01c.html",
        "coachName": "Andy Greer",
        "coachTitle": "Assistant Coach",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/ewingpa01c.html",
        "coachName": "Patrick Ewing",
        "coachTitle": "Assistant Coach",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/cliffst99c.html",
        "coachName": "Steve Clifford",
        "coachTitle": "Assistant Coach",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "coachId": "/coaches/westhpa99c.html",
        "coachName": "Paul Westhead",
        "coachTitle": "Assistant Coach",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/riverdo01c.html",
        "coachName": "Doc Rivers",
        "coachTitle": "Head Coach",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/presspa01c.html",
        "coachName": "Paul Pressey",
        "coachTitle": "Assistant Coach",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/hughema01c.html",
        "coachName": "Mark Hughes",
        "coachTitle": "Assistant Coach",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/wohlda01c.html",
        "coachName": "Dave Wohl",
        "coachTitle": "Assistant Coach",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/riverdo01c.html",
        "coachName": "Doc Rivers",
        "coachTitle": "Head Coach",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/presspa01c.html",
        "coachName": "Paul Pressey",
        "coachTitle": "Assistant Coach",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/hughema01c.html",
        "coachName": "Mark Hughes",
        "coachTitle": "Assistant Coach",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/davisjo01c.html",
        "coachName": "Johnny Davis",
        "coachTitle": "Assistant Coach",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/wohlda01c.html",
        "coachName": "Dave Wohl",
        "coachTitle": "Assistant Coach",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/sternto01c.html",
        "coachName": "Tom Sterner",
        "coachTitle": "Assistant Coach",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/riverdo01c.html",
        "coachName": "Doc Rivers",
        "coachTitle": "Head Coach",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/presspa01c.html",
        "coachName": "Paul Pressey",
        "coachTitle": "Assistant Coach",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/davisjo01c.html",
        "coachName": "Johnny Davis",
        "coachTitle": "Assistant Coach",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/wohlda01c.html",
        "coachName": "Dave Wohl",
        "coachTitle": "Assistant Coach",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/sternto01c.html",
        "coachName": "Tom Sterner",
        "coachTitle": "Assistant Coach",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/riverdo01c.html",
        "coachName": "Doc Rivers",
        "coachTitle": "Head Coach",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/presspa01c.html",
        "coachName": "Paul Pressey",
        "coachTitle": "Assistant Coach",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/davisjo01c.html",
        "coachName": "Johnny Davis",
        "coachTitle": "Assistant Coach",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "coachId": "/coaches/thomaji01c.html",
        "coachName": "Jim Thomas",
        "coachTitle": "Assistant Coach",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/jamesbr01c.html",
        "coachName": "Brian James",
        "coachTitle": "Assistant Coach",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/harrijo01c.html",
        "coachName": "Joe Harrington",
        "coachTitle": "Assistant Coach",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/cartebu01c.html",
        "coachName": "Butch Carter",
        "coachTitle": "Head Coach",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/thomaji01c.html",
        "coachName": "Jim Thomas",
        "coachTitle": "Assistant Coach",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/jamesbr01c.html",
        "coachName": "Brian James",
        "coachTitle": "Assistant Coach",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/harrijo01c.html",
        "coachName": "Joe Harrington",
        "coachTitle": "Assistant Coach",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/cartebu01c.html",
        "coachName": "Butch Carter",
        "coachTitle": "Head Coach",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/zuffebo01c.html",
        "coachName": "Bob Zuffelato",
        "coachTitle": "Assistant Coach",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/walkeda01c.html",
        "coachName": "Darrell Walker",
        "coachTitle": "Head Coach",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/thomaji01c.html",
        "coachName": "Jim Thomas",
        "coachTitle": "Assistant Coach",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/suhrbr01c.html",
        "coachName": "Brendan Suhr",
        "coachTitle": "Assistant Coach",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/shumajo01c.html",
        "coachName": "John Shumate",
        "coachTitle": "Assistant Coach",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/kloppbo99c.html",
        "coachName": "Bob Kloppenburg",
        "coachTitle": "Assistant Coach",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "coachId": "/coaches/cartebu01c.html",
        "coachName": "Butch Carter",
        "coachTitle": "Assistant Coach",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      }
    ]
  };  