<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<!-- <mat-toolbar class="main-toolbar">
    <button mat-flat-button class="nav-button">NBA Knowledge Graph</button>
    <button mat-flat-button class="nav-button" routerLink="players">Player List</button>
    <span class="spacer"> </span>
    <a mat-flat-button class="nav-button" href="http://ec2-54-67-29-193.us-west-1.compute.amazonaws.com:7474/browser/">Neo4j Endpoint</a>
</mat-toolbar> -->
<app-main-toolbar class="main-toolbar"></app-main-toolbar>
<div class="toolbar-spacer"></div>

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<router-outlet></router-outlet>