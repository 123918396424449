import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ALUMNI } from './mock-data/alumni';
import { COACHES } from './mock-data/coaches';
import { PLAYER_BASIC } from './mock-data/player-basic';
import { PLAYER_BASIC_LIST_COMPACT } from './mock-data/player-basic-compact';
import { PLAYER_PERFORMANCE_STAT } from './mock-data/player-prefomance-stat';
import { PLAYER_SERVED_TEAMS } from "./mock-data/player-served-team";
import { SIMILAR_PLAYERS } from './mock-data/similar-player';
import { TEAMMATES } from './mock-data/teammates';
import { AlumniRecord, CoachRecord, PlayerBasicRecord, PlayerCompactRecord, PlayerPerformanceStat, ServedTeamRecord, SimilarPlayerRecord, TeammateRecord} from './player';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor() { }
  
  // getALLPLAYERS
  // GETPALYERSBASIC: ARRAY<ARRY
  // GETPALYERPERFORMANCEPERGAME: ARRAY<PERMORNCE>
  // GETSIMILARPLAYERS(PLAYER_ID): ARRAY<PLAYERS_SIMILAR>

  fetchPlayerBasicFromBackend(playerId:string): Observable<Object> {
    return of(PLAYER_BASIC);
  }

  fetchPlayerServedTeamsFromBackend(playerId:string): Observable<Object> {
    return of(PLAYER_SERVED_TEAMS);
  }

  fetchPlayerPerformanceStat(playerId:string): Observable<Object> {
    return of(PLAYER_PERFORMANCE_STAT);
  }
  
  fetchSimilarPlayres(playreId: string): Observable<Object> {
    return of(SIMILAR_PLAYERS);
  }

  fetchTeammates(playerId: string): Observable<Object> {
    return of(TEAMMATES);
  }

  fetchCoaches(playerId: string): Observable<Object> {
    return of(COACHES);
  }

  fetchHighSchoolAlumni(playerId: string): Observable<Object> {
    return of(ALUMNI);
  }

  fetchCollegeAlumni(playerId: string): Observable<Object> {
    return of(ALUMNI);
  }

  fetchPlayerList(): Observable<Object> {
    return of(PLAYER_BASIC_LIST_COMPACT);
  }

  public getPlayerBasic(playerId: string): Observable<PlayerBasicRecord> {
    return this.fetchPlayerBasicFromBackend(playerId).pipe(
      map(response => {
        let outItem = response["rows"][0] as Object;
        outItem["dateOfBirth"] = new Date(outItem["dateOfBirth"]);
        return outItem as PlayerBasicRecord
      })
    );
  }

  public getPlayerList(): Observable<PlayerCompactRecord[]> {
    return this.fetchPlayerList().pipe(
      map(response => {
        let outItemArray = response["rows"] as Object[];
        return outItemArray.map(item => {
          let outItem = item;
          outItem["dateOfBirth"] = new Date(item["dateOfBirth"]);
          return outItem as PlayerCompactRecord
        });
      })
    );
  }

  public getServedTeams(playerId:string): Observable<ServedTeamRecord[]> {
    return this.fetchPlayerServedTeamsFromBackend(playerId).pipe(
      map(response => {
        let outItem = response["rows"] as Object[];
        return outItem.map(item => item as ServedTeamRecord) as ServedTeamRecord[]
      })
    );
  }

  public getPerformanceStat(playerId: string): Observable<PlayerPerformanceStat[]> {
    return this.fetchPlayerPerformanceStat(playerId).pipe(
      map(response => {
        let outItem = response["rows"] as Object[];
        return outItem.map(item => item as PlayerPerformanceStat) as PlayerPerformanceStat[];
      })
    )
  }

  public getSimilarPlayers(playerId: string): Observable<SimilarPlayerRecord[]> {
    return this.fetchSimilarPlayres(playerId).pipe(
      map(response => {
        let outItemArray = response["rows"] as Object[];
        return outItemArray.map(item => {
          let outItem = item;
          outItem["dateOfBirth"] = new Date(item["dateOfBirth"]);
          return outItem as SimilarPlayerRecord;
        });
      })
    );
  }

  public getTeammates(playerId: string): Observable<TeammateRecord[]> {
    return this.fetchTeammates(playerId).pipe(
      map(response => {
        let outItemArray = response["rows"] as Object[];
        return outItemArray.map(item => {
          let outItem = item;
          outItem["dateOfBirth"] = new Date(item["dateOfBirth"]);
          return outItem as TeammateRecord;
        });
      })
    );
  }

  public getCoaches(playerId: string): Observable<CoachRecord[]> {
    return this.fetchCoaches(playerId).pipe(
      map(response => {
        let outItem = response["rows"] as Object[];
        return outItem as CoachRecord[];
      })
    );
  }

  public getHighSchoolAlumni(playerId: string): Observable<AlumniRecord[]>  {
    return this.fetchHighSchoolAlumni(playerId).pipe(
      map(response => {
        let outItemArray = response["rows"] as Object[];
        return outItemArray.map(item => {
          let outItem = item;
          outItem["dateOfBirth"] = new Date(item["dateOfBirth"]);
          return outItem as AlumniRecord;
        });
      })     
    )
  }

  public getCollegeAlumni(playerId: string): Observable<AlumniRecord[]>  {
    return this.fetchCollegeAlumni(playerId).pipe(
      map(response => {
        let outItemArray = response["rows"] as Object[];
        return outItemArray.map(item => {
          let outItem = item;
          outItem["dateOfBirth"] = new Date(item["dateOfBirth"]);
          return outItem as AlumniRecord;
        });
      })     
    )
  }

  
}
