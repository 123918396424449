import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { DbService } from '../db.service';
import { PlayerTableItem } from '../player-table/player-table-datasource';
import { PlayerTableComponent } from '../player-table/player-table.component';

@Component({
  selector: 'app-player-listpage',
  templateUrl: './player-listpage.component.html',
  styleUrls: ['./player-listpage.component.css']
})
export class PlayerListpageComponent implements OnInit {

  public searchFormControl = new FormControl('');
  public playerList$: Observable<PlayerTableItem[]>;
  @ViewChild(PlayerTableComponent) playerTable: PlayerTableComponent;

  public isSearching: boolean = false;

  startFilter() {
    let searchString: string = this.searchFormControl.value;
    if (searchString == null) {
      searchString = "";
    }

    this.playerTable.setFilterAndSearch(searchString == null ? "" : searchString);
    if ( searchString.length > 0) {
      this.isSearching = true;
    } else {
      this.isSearching = false;
    }
  }
  
  clearFilter() {
    this.searchFormControl.setValue("");
    this.startFilter();
  }
  constructor(
    private dbService: DbService
  ) { }

  ngOnInit(): void {
    this.playerList$ = this.dbService.getPlayerList();
  }

}
