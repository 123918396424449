import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { DbService } from '../db.service';
import { PlayerBasicRecord } from '../player';


@Component({
  selector: 'app-player-details-page',
  templateUrl: './player-details-page.component.html',
  styleUrls: ['./player-details-page.component.css']
})
export class PlayerDetailsPageComponent implements OnInit {

  playerId: string;
  playerBasic$: Observable<PlayerBasicRecord>;
  

  constructor(
    private route: ActivatedRoute,
    private dataSource: DbService
  ) { }

  ngOnInit(): void {
    this.playerId = this.route.snapshot.paramMap.get('id');
    this.playerBasic$ = this.dataSource.getPlayerBasic(this.playerId);
  }

}
