import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatCard, MatCardModule } from "@angular/material/card";

import { MainpageComponent } from './mainpage/mainpage.component';
import { PlayerpageComponent } from './playerpage/playerpage.component';
import { PlayercardComponent } from './playercard/playercard.component';
import { PlayerTableComponent } from './player-table/player-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlayerListpageComponent } from './player-listpage/player-listpage.component';
import { PlayerDetailsPageComponent } from './player-details-page/player-details-page.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from "@angular/material/icon";
import { PlayerDetailsComponent } from './player-details/player-details.component';
import { TeamTableComponent } from './team-table/team-table.component';
import { PerformanceTableComponent } from './performance-table/performance-table.component';
import { SimilarPlayersTableComponent } from './similar-players-table/similar-players-table.component';
import { TeammatesTableComponent } from './teammates-table/teammates-table.component';
import { CoachesTableComponent } from './coaches-table/coaches-table.component';
import { HttpClientModule } from '@angular/common/http';
import { AlumniTableComponent } from './alumni-table/alumni-table.component';
import { DbService } from './db.service';
import { DbHttpService } from './db-http.service';
import { environment } from 'src/environments/environment';

import { ShortPlayerIdPipe } from './short-player-id.pipe';
import { CommonModule } from '@angular/common';
import { MainToolbarComponent } from './main-toolbar/main-toolbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from "@angular/material/input";
@NgModule({
  declarations: [
    AppComponent,
    MainpageComponent,
    PlayerpageComponent,
    PlayercardComponent,
    PlayerTableComponent,
    PlayerListpageComponent,
    PlayerDetailsPageComponent,
    PlayerDetailsComponent,
    TeamTableComponent,
    PerformanceTableComponent,
    SimilarPlayersTableComponent,
    TeammatesTableComponent,
    CoachesTableComponent,
    AlumniTableComponent,
    ShortPlayerIdPipe,
    MainToolbarComponent

  ],

  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgbModule,
    MatFormFieldModule,
    MatIconModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
  ],
  providers: [  
    // { provide: DbService, useClass: environment.production ? DbHttpService : DbService}
    { provide: DbService, useClass: DbHttpService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
