export const TEAMMATES = {
    "total": 294,
    "msg": "success",
    "rows": [
      {
        "playerId": "/players/s/splitti01.html",
        "playerName": "Tiago Splitter",
        "dateOfBirth": "1985-01-01",
        "placeOfBirth": "Joinville, Brazil",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/p/parketo01.html",
        "playerName": "Tony Parker",
        "dateOfBirth": "1982-05-17",
        "placeOfBirth": "Bruges, Belgium",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/n/nealga01.html",
        "playerName": "Gary Neal",
        "dateOfBirth": "1984-10-03",
        "placeOfBirth": "Baltimore, Maryland",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/m/millspa02.html",
        "playerName": "Patty Mills",
        "dateOfBirth": "1988-08-11",
        "placeOfBirth": "Canberra, Australia",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/l/leonaka01.html",
        "playerName": "Kawhi Leonard",
        "dateOfBirth": "1991-06-29",
        "placeOfBirth": "Los Angeles, California",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/j/josepco01.html",
        "playerName": "Cory Joseph",
        "dateOfBirth": "1991-08-20",
        "placeOfBirth": "Toronto, Canada",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/j/jacksst02.html",
        "playerName": "Stephen Jackson",
        "dateOfBirth": "1978-04-05",
        "placeOfBirth": "Houston, Texas",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/g/greenda02.html",
        "playerName": "Danny Green",
        "dateOfBirth": "1987-06-22",
        "placeOfBirth": "North Babylon, New York",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/g/ginobma01.html",
        "playerName": "Manu Ginóbili",
        "dateOfBirth": "1977-07-28",
        "placeOfBirth": "Bahia Blanca, Argentina",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/d/duncati01.html",
        "playerName": "Tim Duncan",
        "dateOfBirth": "1976-04-25",
        "placeOfBirth": "St. Croix, U.S. Virgin Islands",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/d/diawbo01.html",
        "playerName": "Boris Diaw",
        "dateOfBirth": "1982-04-16",
        "placeOfBirth": "Cormeilles-en-Parisis, France",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/d/decolna01.html",
        "playerName": "Nando De Colo",
        "dateOfBirth": "1987-06-23",
        "placeOfBirth": "Sainte-Catherine-les-Arras, France",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/b/bonnema01.html",
        "playerName": "Matt Bonner",
        "dateOfBirth": "1980-04-05",
        "placeOfBirth": "Concord, New Hampshire",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/b/blairde01.html",
        "playerName": "DeJuan Blair",
        "dateOfBirth": "1989-04-22",
        "placeOfBirth": "Pittsburgh, Pennsylvania",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/b/baynear01.html",
        "playerName": "Aron Baynes",
        "dateOfBirth": "1986-12-09",
        "placeOfBirth": "Gisborne, New Zealand",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/a/anderja01.html",
        "playerName": "James Anderson",
        "dateOfBirth": "1989-03-25",
        "placeOfBirth": "El Dorado, Arkansas",
        "season": "2012-13",
        "atTeam": "San Antonio Spurs"
      },
      {
        "playerId": "/players/w/willima02.html",
        "playerName": "Marvin Williams",
        "dateOfBirth": "1986-06-19",
        "placeOfBirth": "Bremerton, Washington",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/t/teaguje01.html",
        "playerName": "Jeff Teague",
        "dateOfBirth": "1988-06-10",
        "placeOfBirth": "Indianapolis, Indiana",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/s/stackje01.html",
        "playerName": "Jerry Stackhouse",
        "dateOfBirth": "1974-11-05",
        "placeOfBirth": "Kinston, North Carolina",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/s/smithjo03.html",
        "playerName": "Josh Smith",
        "dateOfBirth": "1985-12-05",
        "placeOfBirth": "College Park, Georgia",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/s/sloando01.html",
        "playerName": "Donald Sloan",
        "dateOfBirth": "1988-01-15",
        "placeOfBirth": "Shreveport, Louisiana",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/r/radmavl01.html",
        "playerName": "Vladimir Radmanović",
        "dateOfBirth": "1980-11-19",
        "placeOfBirth": "Trebinje, Bosnia and Herzegovina",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/p/pargoja01.html",
        "playerName": "Jannero Pargo",
        "dateOfBirth": "1979-10-22",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/p/pachuza01.html",
        "playerName": "Zaza Pachulia",
        "dateOfBirth": "1984-02-10",
        "placeOfBirth": "Tbilisi, Georgia",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/j/johnsjo02.html",
        "playerName": "Joe Johnson",
        "dateOfBirth": "1981-06-29",
        "placeOfBirth": "Little Rock, Arkansas",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/j/johnsiv01.html",
        "playerName": "Ivan Johnson",
        "dateOfBirth": "1984-04-10",
        "placeOfBirth": "San Antonio, Texas",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/h/horfoal01.html",
        "playerName": "Al Horford",
        "dateOfBirth": "1986-06-03",
        "placeOfBirth": "Puerto Plata, Dominican Republic",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/h/hinriki01.html",
        "playerName": "Kirk Hinrich",
        "dateOfBirth": "1981-01-02",
        "placeOfBirth": "Sioux City, Iowa",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/g/greenwi01.html",
        "playerName": "Willie Green",
        "dateOfBirth": "1981-07-28",
        "placeOfBirth": "Detroit, Michigan",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/d/dampier01.html",
        "playerName": "Erick Dampier",
        "dateOfBirth": "1975-07-14",
        "placeOfBirth": "Jackson, Mississippi",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/c/collija04.html",
        "playerName": "Jason Collins",
        "dateOfBirth": "1978-12-02",
        "placeOfBirth": "Northridge, California",
        "season": "2011-12",
        "atTeam": "Atlanta Hawks"
      },
      {
        "playerId": "/players/w/wilcoch01.html",
        "playerName": "Chris Wilcox",
        "dateOfBirth": "1982-09-03",
        "placeOfBirth": "Raleigh, North Carolina",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/w/wallabe01.html",
        "playerName": "Ben Wallace",
        "dateOfBirth": "1974-09-10",
        "placeOfBirth": "White Hall, Alabama",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/v/villach01.html",
        "playerName": "Charlie Villanueva",
        "dateOfBirth": "1984-08-24",
        "placeOfBirth": "Queens, New York",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/s/summeda01.html",
        "playerName": "DaJuan Summers",
        "dateOfBirth": "1988-01-24",
        "placeOfBirth": "Baltimore, Maryland",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/s/stuckro01.html",
        "playerName": "Rodney Stuckey",
        "dateOfBirth": "1986-04-21",
        "placeOfBirth": "Seattle, Washington",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/p/princta01.html",
        "playerName": "Tayshaun Prince",
        "dateOfBirth": "1980-02-28",
        "placeOfBirth": "Compton, California",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/m/monrogr01.html",
        "playerName": "Greg Monroe",
        "dateOfBirth": "1990-06-04",
        "placeOfBirth": "New Orleans, Louisiana",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/m/maxieja01.html",
        "playerName": "Jason Maxiell",
        "dateOfBirth": "1983-02-18",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/h/hamilri01.html",
        "playerName": "Richard Hamilton",
        "dateOfBirth": "1978-02-14",
        "placeOfBirth": "Coatesville, Pennsylvania",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/g/gordobe01.html",
        "playerName": "Ben Gordon",
        "dateOfBirth": "1983-04-04",
        "placeOfBirth": "London, United Kingdom",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/d/dayeau01.html",
        "playerName": "Austin Daye",
        "dateOfBirth": "1988-06-05",
        "placeOfBirth": "Irvine, California",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/b/bynumwi01.html",
        "playerName": "Will Bynum",
        "dateOfBirth": "1983-01-04",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2010-11",
        "atTeam": "Detroit Pistons"
      },
      {
        "playerId": "/players/w/walkebi01.html",
        "playerName": "Henry Walker",
        "dateOfBirth": "1987-10-09",
        "placeOfBirth": "Huntington, West Virginia",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/r/rodrise01.html",
        "playerName": "Sergio Rodríguez",
        "dateOfBirth": "1986-06-12",
        "placeOfBirth": "Tenerife, Spain",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/r/robinna01.html",
        "playerName": "Nate Robinson",
        "dateOfBirth": "1984-05-31",
        "placeOfBirth": "Seattle, Washington",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/m/milicda01.html",
        "playerName": "Darko Miličić",
        "dateOfBirth": "1985-06-20",
        "placeOfBirth": "Novi Sad, Serbia",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/l/leeda02.html",
        "playerName": "David Lee",
        "dateOfBirth": "1983-04-29",
        "placeOfBirth": "St. Louis, Missouri",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/l/landrma01.html",
        "playerName": "Marcus Landry",
        "dateOfBirth": "1985-11-01",
        "placeOfBirth": "Milwaukee, Wisconsin",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/j/jeffrja01.html",
        "playerName": "Jared Jeffries",
        "dateOfBirth": "1981-11-25",
        "placeOfBirth": "Bloomington, Indiana",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/h/hughela01.html",
        "playerName": "Larry Hughes",
        "dateOfBirth": "1979-01-23",
        "placeOfBirth": "St. Louis, Missouri",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/h/houseed01.html",
        "playerName": "Eddie House",
        "dateOfBirth": "1978-05-14",
        "placeOfBirth": "Berkeley, California",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/h/hilljo01.html",
        "playerName": "Jordan Hill",
        "dateOfBirth": "1987-07-27",
        "placeOfBirth": "Atlanta, Georgia",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/h/harrial01.html",
        "playerName": "Al Harrington",
        "dateOfBirth": "1980-02-17",
        "placeOfBirth": "Orange, New Jersey",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/g/giddejr01.html",
        "playerName": "J.R. Giddens",
        "dateOfBirth": "1985-02-13",
        "placeOfBirth": "Oklahoma City, Oklahoma",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/g/gallida01.html",
        "playerName": "Danilo Gallinari",
        "dateOfBirth": "1988-08-08",
        "placeOfBirth": "Sant'Angelo Lodigiano, Italy",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/d/duhonch01.html",
        "playerName": "Chris Duhon",
        "dateOfBirth": "1982-08-31",
        "placeOfBirth": "Mamou, Louisiana",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/d/douglto01.html",
        "playerName": "Toney Douglas",
        "dateOfBirth": "1986-03-16",
        "placeOfBirth": "Jonesboro, Georgia",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/c/curryed01.html",
        "playerName": "Eddy Curry",
        "dateOfBirth": "1982-12-05",
        "placeOfBirth": "Harvey, Illinois",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/c/chandwi01.html",
        "playerName": "Wilson Chandler",
        "dateOfBirth": "1987-05-10",
        "placeOfBirth": "Benton Harbor, Michigan",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/b/bendejo01.html",
        "playerName": "Jonathan Bender",
        "dateOfBirth": "1981-01-30",
        "placeOfBirth": "Picayune, Mississippi",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/b/barroea01.html",
        "playerName": "Earl Barron",
        "dateOfBirth": "1981-08-14",
        "placeOfBirth": "Clarksdale, Mississippi",
        "season": "2009-10",
        "atTeam": "New York Knicks"
      },
      {
        "playerId": "/players/t/templga01.html",
        "playerName": "Garrett Temple",
        "dateOfBirth": "1986-05-08",
        "placeOfBirth": "Baton Rouge, Louisiana",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/t/tayloje02.html",
        "playerName": "Jermaine Taylor",
        "dateOfBirth": "1986-12-08",
        "placeOfBirth": "Tavares, Florida",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/s/scolalu01.html",
        "playerName": "Luis Scola",
        "dateOfBirth": "1980-04-30",
        "placeOfBirth": "Buenos Aires, Argentina",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mensapo01.html",
        "playerName": "Pops Mensah-Bonsu",
        "dateOfBirth": "1983-09-07",
        "placeOfBirth": "London, United Kingdom",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/martike02.html",
        "playerName": "Kevin Martin",
        "dateOfBirth": "1983-02-01",
        "placeOfBirth": "Zanesville, Ohio",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/l/lowryky01.html",
        "playerName": "Kyle Lowry",
        "dateOfBirth": "1986-03-25",
        "placeOfBirth": "Philadelphia, Pennsylvania",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/l/landrca01.html",
        "playerName": "Carl Landry",
        "dateOfBirth": "1983-09-19",
        "placeOfBirth": "Milwaukee, Wisconsin",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/j/jeffrja01.html",
        "playerName": "Jared Jeffries",
        "dateOfBirth": "1981-11-25",
        "placeOfBirth": "Bloomington, Indiana",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/hilljo01.html",
        "playerName": "Jordan Hill",
        "dateOfBirth": "1987-07-27",
        "placeOfBirth": "Atlanta, Georgia",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/hayesch01.html",
        "playerName": "Chuck Hayes",
        "dateOfBirth": "1983-06-11",
        "placeOfBirth": "San Leandro, California",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/harrimi01.html",
        "playerName": "Mike Harris",
        "dateOfBirth": "1983-06-15",
        "placeOfBirth": "Hillsboro, Texas",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/d/dorsejo01.html",
        "playerName": "Joey Dorsey",
        "dateOfBirth": "1983-12-16",
        "placeOfBirth": "Baltimore, Maryland",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/c/cookbr01.html",
        "playerName": "Brian Cook",
        "dateOfBirth": "1980-12-04",
        "placeOfBirth": "Lincoln, Illinois",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/c/conrowi01.html",
        "playerName": "Will Conroy",
        "dateOfBirth": "1982-12-08",
        "placeOfBirth": "Portland, Oregon",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/budinch01.html",
        "playerName": "Chase Budinger",
        "dateOfBirth": "1988-05-22",
        "placeOfBirth": "Encinitas, California",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/brookaa01.html",
        "playerName": "Aaron Brooks",
        "dateOfBirth": "1985-01-14",
        "placeOfBirth": "Seattle, Washington",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/battish01.html",
        "playerName": "Shane Battier",
        "dateOfBirth": "1978-09-09",
        "placeOfBirth": "Birmingham, Michigan",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/a/armsthi01.html",
        "playerName": "Hilton Armstrong",
        "dateOfBirth": "1984-11-11",
        "placeOfBirth": "Peekskill, New York",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/a/arizatr01.html",
        "playerName": "Trevor Ariza",
        "dateOfBirth": "1985-06-30",
        "placeOfBirth": "Miami, Florida",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/a/anderda03.html",
        "playerName": "David Andersen",
        "dateOfBirth": "1980-06-23",
        "placeOfBirth": "Carlton, Australia",
        "season": "2009-10",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/whiteja02.html",
        "playerName": "James White",
        "dateOfBirth": "1982-10-21",
        "placeOfBirth": "Washington, District of Columbia",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/wafervo01.html",
        "playerName": "Von Wafer",
        "dateOfBirth": "1985-07-21",
        "placeOfBirth": "Homer, Louisiana",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/s/scolalu01.html",
        "playerName": "Luis Scola",
        "dateOfBirth": "1980-04-30",
        "placeOfBirth": "Buenos Aires, Argentina",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mutomdi01.html",
        "playerName": "Dikembe Mutombo",
        "dateOfBirth": "1966-06-25",
        "placeOfBirth": "Kinshasa, Democratic Republic of the Congo",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mingya01.html",
        "playerName": "Yao Ming",
        "dateOfBirth": "1980-09-12",
        "placeOfBirth": "Shanghai, China",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/l/lowryky01.html",
        "playerName": "Kyle Lowry",
        "dateOfBirth": "1986-03-25",
        "placeOfBirth": "Philadelphia, Pennsylvania",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/l/landrca01.html",
        "playerName": "Carl Landry",
        "dateOfBirth": "1983-09-19",
        "placeOfBirth": "Milwaukee, Wisconsin",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/headlu01.html",
        "playerName": "Luther Head",
        "dateOfBirth": "1982-11-26",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/hayesch01.html",
        "playerName": "Chuck Hayes",
        "dateOfBirth": "1983-06-11",
        "placeOfBirth": "San Leandro, California",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/d/dorsejo01.html",
        "playerName": "Joey Dorsey",
        "dateOfBirth": "1983-12-16",
        "placeOfBirth": "Baltimore, Maryland",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/c/cookbr01.html",
        "playerName": "Brian Cook",
        "dateOfBirth": "1980-12-04",
        "placeOfBirth": "Lincoln, Illinois",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/brookaa01.html",
        "playerName": "Aaron Brooks",
        "dateOfBirth": "1985-01-14",
        "placeOfBirth": "Seattle, Washington",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/battish01.html",
        "playerName": "Shane Battier",
        "dateOfBirth": "1978-09-09",
        "placeOfBirth": "Birmingham, Michigan",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/barrybr01.html",
        "playerName": "Brent Barry",
        "dateOfBirth": "1971-12-31",
        "placeOfBirth": "Hempstead, New York",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/a/artesro01.html",
        "playerName": "Metta World Peace",
        "dateOfBirth": "1979-11-13",
        "placeOfBirth": "Queensbridge, New York",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/a/alstora01.html",
        "playerName": "Rafer Alston",
        "dateOfBirth": "1976-07-24",
        "placeOfBirth": "New York, New York",
        "season": "2008-09",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/woodslo01.html",
        "playerName": "Loren Woods",
        "dateOfBirth": "1978-06-21",
        "placeOfBirth": "St. Louis, Missouri",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/williju01.html",
        "playerName": "Justin Williams",
        "dateOfBirth": "1984-05-12",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/wellsbo01.html",
        "playerName": "Bonzi Wells",
        "dateOfBirth": "1976-09-28",
        "placeOfBirth": "Muncie, Indiana",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/s/snydeki01.html",
        "playerName": "Kirk Snyder",
        "dateOfBirth": "1983-06-05",
        "placeOfBirth": "Los Angeles, California",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/s/scolalu01.html",
        "playerName": "Luis Scola",
        "dateOfBirth": "1980-04-30",
        "placeOfBirth": "Buenos Aires, Argentina",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/n/novakst01.html",
        "playerName": "Steve Novak",
        "dateOfBirth": "1983-06-13",
        "placeOfBirth": "Libertyville, Illinois",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mutomdi01.html",
        "playerName": "Dikembe Mutombo",
        "dateOfBirth": "1966-06-25",
        "placeOfBirth": "Kinshasa, Democratic Republic of the Congo",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mingya01.html",
        "playerName": "Yao Ming",
        "dateOfBirth": "1980-09-12",
        "placeOfBirth": "Shanghai, China",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/l/landrca01.html",
        "playerName": "Carl Landry",
        "dateOfBirth": "1983-09-19",
        "placeOfBirth": "Milwaukee, Wisconsin",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/j/jonesbo02.html",
        "playerName": "Bobby Jones",
        "dateOfBirth": "1984-01-09",
        "placeOfBirth": "Compton, California",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/j/jamesmi01.html",
        "playerName": "Mike James",
        "dateOfBirth": "1975-06-23",
        "placeOfBirth": "Copiague, New York",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/j/jacksbo01.html",
        "playerName": "Bobby Jackson",
        "dateOfBirth": "1973-03-13",
        "placeOfBirth": "East Spencer, North Carolina",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/headlu01.html",
        "playerName": "Luther Head",
        "dateOfBirth": "1982-11-26",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/hayesch01.html",
        "playerName": "Chuck Hayes",
        "dateOfBirth": "1983-06-11",
        "placeOfBirth": "San Leandro, California",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/harrimi01.html",
        "playerName": "Mike Harris",
        "dateOfBirth": "1983-06-15",
        "placeOfBirth": "Hillsboro, Texas",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/g/greenge01.html",
        "playerName": "Gerald Green",
        "dateOfBirth": "1986-01-26",
        "placeOfBirth": "Houston, Texas",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/f/francst01.html",
        "playerName": "Steve Francis",
        "dateOfBirth": "1977-02-21",
        "placeOfBirth": "Silver Spring, Maryland",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/brookaa01.html",
        "playerName": "Aaron Brooks",
        "dateOfBirth": "1985-01-14",
        "placeOfBirth": "Seattle, Washington",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/battish01.html",
        "playerName": "Shane Battier",
        "dateOfBirth": "1978-09-09",
        "placeOfBirth": "Birmingham, Michigan",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/a/alstora01.html",
        "playerName": "Rafer Alston",
        "dateOfBirth": "1976-07-24",
        "placeOfBirth": "New York, New York",
        "season": "2007-08",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/wellsbo01.html",
        "playerName": "Bonzi Wells",
        "dateOfBirth": "1976-09-28",
        "placeOfBirth": "Muncie, Indiana",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/t/tsakaja01.html",
        "playerName": "Jake Tsakalidis",
        "dateOfBirth": "1979-06-10",
        "placeOfBirth": "Rustavi, Georgia",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/s/spanova01.html",
        "playerName": "Vassilis Spanoulis",
        "dateOfBirth": "1982-08-07",
        "placeOfBirth": "Larissa, Greece",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/s/snydeki01.html",
        "playerName": "Kirk Snyder",
        "dateOfBirth": "1983-06-05",
        "placeOfBirth": "Los Angeles, California",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/p/padgesc01.html",
        "playerName": "Scott Padgett",
        "dateOfBirth": "1976-04-19",
        "placeOfBirth": "Louisville, Kentucky",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/n/novakst01.html",
        "playerName": "Steve Novak",
        "dateOfBirth": "1983-06-13",
        "placeOfBirth": "Libertyville, Illinois",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mutomdi01.html",
        "playerName": "Dikembe Mutombo",
        "dateOfBirth": "1966-06-25",
        "placeOfBirth": "Kinshasa, Democratic Republic of the Congo",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mingya01.html",
        "playerName": "Yao Ming",
        "dateOfBirth": "1980-09-12",
        "placeOfBirth": "Shanghai, China",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/l/lucasjo02.html",
        "playerName": "John Lucas III",
        "dateOfBirth": "1982-11-21",
        "placeOfBirth": "Washington, District of Columbia",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/howarju01.html",
        "playerName": "Juwan Howard",
        "dateOfBirth": "1973-02-07",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/headlu01.html",
        "playerName": "Luther Head",
        "dateOfBirth": "1982-11-26",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/hayesch01.html",
        "playerName": "Chuck Hayes",
        "dateOfBirth": "1983-06-11",
        "placeOfBirth": "San Leandro, California",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/battish01.html",
        "playerName": "Shane Battier",
        "dateOfBirth": "1978-09-09",
        "placeOfBirth": "Birmingham, Michigan",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/a/alstora01.html",
        "playerName": "Rafer Alston",
        "dateOfBirth": "1976-07-24",
        "placeOfBirth": "New York, New York",
        "season": "2006-07",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/wesleda01.html",
        "playerName": "David Wesley",
        "dateOfBirth": "1970-11-14",
        "placeOfBirth": "San Antonio, Texas",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/s/swiftst01.html",
        "playerName": "Stromile Swift",
        "dateOfBirth": "1979-11-21",
        "placeOfBirth": "Shreveport, Louisiana",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/n/norrimo01.html",
        "playerName": "Moochie Norris",
        "dateOfBirth": "1973-07-27",
        "placeOfBirth": "Washington, District of Columbia",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mutomdi01.html",
        "playerName": "Dikembe Mutombo",
        "dateOfBirth": "1966-06-25",
        "placeOfBirth": "Kinshasa, Democratic Republic of the Congo",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mingya01.html",
        "playerName": "Yao Ming",
        "dateOfBirth": "1980-09-12",
        "placeOfBirth": "Shanghai, China",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/l/lucasjo02.html",
        "playerName": "John Lucas III",
        "dateOfBirth": "1982-11-21",
        "placeOfBirth": "Washington, District of Columbia",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/l/lampema01.html",
        "playerName": "Maciej Lampe",
        "dateOfBirth": "1985-02-05",
        "placeOfBirth": "Lodz, Poland",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/howarju01.html",
        "playerName": "Juwan Howard",
        "dateOfBirth": "1973-02-07",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/headlu01.html",
        "playerName": "Luther Head",
        "dateOfBirth": "1982-11-26",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/hayesch01.html",
        "playerName": "Chuck Hayes",
        "dateOfBirth": "1983-06-11",
        "placeOfBirth": "San Leandro, California",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/g/grahast01.html",
        "playerName": "Stephen Graham",
        "dateOfBirth": "1982-06-11",
        "placeOfBirth": "Wilmington, Delaware",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/f/frahmri01.html",
        "playerName": "Richie Frahm",
        "dateOfBirth": "1977-08-14",
        "placeOfBirth": "Battle Ground, Washington",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/d/davisjo02.html",
        "playerName": "Josh Davis",
        "dateOfBirth": "1980-08-10",
        "placeOfBirth": "Salem, Oregon",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/brunsri01.html",
        "playerName": "Rick Brunson",
        "dateOfBirth": "1972-06-14",
        "placeOfBirth": "Syracuse, New York",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/bowenry01.html",
        "playerName": "Ryan Bowen",
        "dateOfBirth": "1975-11-20",
        "placeOfBirth": "Fort Madison, Iowa",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/boganke01.html",
        "playerName": "Keith Bogans",
        "dateOfBirth": "1980-05-12",
        "placeOfBirth": "Alexandria, Virginia",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/baxtelo01.html",
        "playerName": "Lonny Baxter",
        "dateOfBirth": "1979-01-27",
        "placeOfBirth": "Silver Spring, Maryland",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/barryjo01.html",
        "playerName": "Jon Barry",
        "dateOfBirth": "1969-07-25",
        "placeOfBirth": "Oakland, California",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/a/anderde01.html",
        "playerName": "Derek Anderson",
        "dateOfBirth": "1974-07-18",
        "placeOfBirth": "Louisville, Kentucky",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/a/alstora01.html",
        "playerName": "Rafer Alston",
        "dateOfBirth": "1976-07-24",
        "placeOfBirth": "New York, New York",
        "season": "2005-06",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/wesleda01.html",
        "playerName": "David Wesley",
        "dateOfBirth": "1970-11-14",
        "placeOfBirth": "San Antonio, Texas",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/weathcl01.html",
        "playerName": "Clarence Weatherspoon",
        "dateOfBirth": "1970-09-08",
        "placeOfBirth": "Crawford, Mississippi",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/wardch01.html",
        "playerName": "Charlie Ward",
        "dateOfBirth": "1970-10-12",
        "placeOfBirth": "Thomasville, Georgia",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/t/tayloma01.html",
        "playerName": "Maurice Taylor",
        "dateOfBirth": "1976-10-30",
        "placeOfBirth": "Detroit, Michigan",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/s/surabo01.html",
        "playerName": "Bob Sura",
        "dateOfBirth": "1973-03-25",
        "placeOfBirth": "Wilkes-Barre, Pennsylvania",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/s/stricro02.html",
        "playerName": "Rod Strickland",
        "dateOfBirth": "1966-07-11",
        "placeOfBirth": "Bronx, New York",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/p/padgesc01.html",
        "playerName": "Scott Padgett",
        "dateOfBirth": "1976-04-19",
        "placeOfBirth": "Louisville, Kentucky",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/n/norrimo01.html",
        "playerName": "Moochie Norris",
        "dateOfBirth": "1973-07-27",
        "placeOfBirth": "Washington, District of Columbia",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/n/nachbbo01.html",
        "playerName": "Boštjan Nachbar",
        "dateOfBirth": "1980-07-03",
        "placeOfBirth": "Slovenj Gradec, Slovenia",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mutomdi01.html",
        "playerName": "Dikembe Mutombo",
        "dateOfBirth": "1966-06-25",
        "placeOfBirth": "Kinshasa, Democratic Republic of the Congo",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/m/mingya01.html",
        "playerName": "Yao Ming",
        "dateOfBirth": "1980-09-12",
        "placeOfBirth": "Shanghai, China",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/l/luety01.html",
        "playerName": "Tyronn Lue",
        "dateOfBirth": "1977-05-03",
        "placeOfBirth": "Mexico, Missouri",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/k/knighbr02.html",
        "playerName": "Brandin Knight",
        "dateOfBirth": "1981-12-16",
        "placeOfBirth": "Livingston, New Jersey",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/j/jamesmi01.html",
        "playerName": "Mike James",
        "dateOfBirth": "1975-06-23",
        "placeOfBirth": "Copiague, New York",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/j/jacksji01.html",
        "playerName": "Jim Jackson",
        "dateOfBirth": "1970-10-14",
        "placeOfBirth": "Toledo, Ohio",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/h/howarju01.html",
        "playerName": "Juwan Howard",
        "dateOfBirth": "1973-02-07",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/g/gainere01.html",
        "playerName": "Reece Gaines",
        "dateOfBirth": "1981-01-07",
        "placeOfBirth": "Madison, Wisconsin",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/braggto01.html",
        "playerName": "Torraye Braggs",
        "dateOfBirth": "1976-05-15",
        "placeOfBirth": "Fresno, California",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/bowenry01.html",
        "playerName": "Ryan Bowen",
        "dateOfBirth": "1975-11-20",
        "placeOfBirth": "Fort Madison, Iowa",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/barryjo01.html",
        "playerName": "Jon Barry",
        "dateOfBirth": "1969-07-25",
        "placeOfBirth": "Oakland, California",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/barrean01.html",
        "playerName": "Andre Barrett",
        "dateOfBirth": "1982-02-21",
        "placeOfBirth": "Bronx, New York",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/b/bakervi01.html",
        "playerName": "Vin Baker",
        "dateOfBirth": "1971-11-23",
        "placeOfBirth": "Lake Wales, Florida",
        "season": "2004-05",
        "atTeam": "Houston Rockets"
      },
      {
        "playerId": "/players/w/willish01.html",
        "playerName": "Shammond Williams",
        "dateOfBirth": "1975-04-05",
        "placeOfBirth": "Bronx, New York",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/s/stricro02.html",
        "playerName": "Rod Strickland",
        "dateOfBirth": "1966-07-11",
        "placeOfBirth": "Bronx, New York",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/s/stevede01.html",
        "playerName": "DeShawn Stevenson",
        "dateOfBirth": "1981-04-03",
        "placeOfBirth": "Fresno, California",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/r/rooksse01.html",
        "playerName": "Sean Rooks",
        "dateOfBirth": "1969-09-09",
        "placeOfBirth": "New York, New York",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/p/penigde01.html",
        "playerName": "Desmond Penigar",
        "dateOfBirth": "1981-07-16",
        "placeOfBirth": "Upland, California",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/p/pachuza01.html",
        "playerName": "Zaza Pachulia",
        "dateOfBirth": "1984-02-10",
        "placeOfBirth": "Tbilisi, Georgia",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/n/nailole01.html",
        "playerName": "Lee Nailon",
        "dateOfBirth": "1975-02-22",
        "placeOfBirth": "South Bend, Indiana",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/l/luety01.html",
        "playerName": "Tyronn Lue",
        "dateOfBirth": "1977-05-03",
        "placeOfBirth": "Mexico, Missouri",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/j/johnsbr01.html",
        "playerName": "Britton Johnsen",
        "dateOfBirth": "1979-07-08",
        "placeOfBirth": "Salt Lake City, Utah",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/huntest01.html",
        "playerName": "Steven Hunter",
        "dateOfBirth": "1981-10-31",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/howarju01.html",
        "playerName": "Juwan Howard",
        "dateOfBirth": "1973-02-07",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/harvedo01.html",
        "playerName": "Donnell Harvey",
        "dateOfBirth": "1980-08-26",
        "placeOfBirth": "Shellman, Georgia",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/goodedr01.html",
        "playerName": "Drew Gooden",
        "dateOfBirth": "1981-09-24",
        "placeOfBirth": "Oakland, California",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/giricgo01.html",
        "playerName": "Gordan Giriček",
        "dateOfBirth": "1977-06-20",
        "placeOfBirth": "Zagreb, Croatia",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/garripa01.html",
        "playerName": "Pat Garrity",
        "dateOfBirth": "1976-08-23",
        "placeOfBirth": "Las Vegas, Nevada",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/gainere01.html",
        "playerName": "Reece Gaines",
        "dateOfBirth": "1981-01-07",
        "placeOfBirth": "Madison, Wisconsin",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/d/dialde01.html",
        "playerName": "Derrick Dial",
        "dateOfBirth": "1975-12-20",
        "placeOfBirth": "Detroit, Michigan",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/d/declean01.html",
        "playerName": "Andrew DeClercq",
        "dateOfBirth": "1973-02-01",
        "placeOfBirth": "Detroit, Michigan",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/b/boganke01.html",
        "playerName": "Keith Bogans",
        "dateOfBirth": "1980-05-12",
        "placeOfBirth": "Alexandria, Virginia",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/a/archiro01.html",
        "playerName": "Robert Archibald",
        "dateOfBirth": "1980-03-29",
        "placeOfBirth": "Paisley, United Kingdom",
        "season": "2003-04",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/w/whitnch01.html",
        "playerName": "Chris Whitney",
        "dateOfBirth": "1971-10-05",
        "placeOfBirth": "Hopkinsville, Kentucky",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/v/vaughja01.html",
        "playerName": "Jacque Vaughn",
        "dateOfBirth": "1975-02-11",
        "placeOfBirth": "Los Angeles, California",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/s/sasseje01.html",
        "playerName": "Jeryl Sasser",
        "dateOfBirth": "1979-02-13",
        "placeOfBirth": "Dallas, Texas",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/o/oyedeol01.html",
        "playerName": "Olumide Oyedeji",
        "dateOfBirth": "1981-05-11",
        "placeOfBirth": "Ibadan, Nigeria",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/m/millemi01.html",
        "playerName": "Mike Miller",
        "dateOfBirth": "1980-02-19",
        "placeOfBirth": "Mitchell, South Dakota",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/k/kempsh01.html",
        "playerName": "Shawn Kemp",
        "dateOfBirth": "1969-11-26",
        "placeOfBirth": "Elkhart, Indiana",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/huntest01.html",
        "playerName": "Steven Hunter",
        "dateOfBirth": "1981-10-31",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/humphry01.html",
        "playerName": "Ryan Humphrey",
        "dateOfBirth": "1979-07-24",
        "placeOfBirth": "Tulsa, Oklahoma",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/hillgr01.html",
        "playerName": "Grant Hill",
        "dateOfBirth": "1972-10-05",
        "placeOfBirth": "Dallas, Texas",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/grantho01.html",
        "playerName": "Horace Grant",
        "dateOfBirth": "1965-07-04",
        "placeOfBirth": "Augusta, Georgia",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/goodedr01.html",
        "playerName": "Drew Gooden",
        "dateOfBirth": "1981-09-24",
        "placeOfBirth": "Oakland, California",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/giricgo01.html",
        "playerName": "Gordan Giriček",
        "dateOfBirth": "1977-06-20",
        "placeOfBirth": "Zagreb, Croatia",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/garripa01.html",
        "playerName": "Pat Garrity",
        "dateOfBirth": "1976-08-23",
        "placeOfBirth": "Las Vegas, Nevada",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/d/declean01.html",
        "playerName": "Andrew DeClercq",
        "dateOfBirth": "1973-02-01",
        "placeOfBirth": "Detroit, Michigan",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/b/burkepa01.html",
        "playerName": "Pat Burke",
        "dateOfBirth": "1973-12-14",
        "placeOfBirth": "Dublin, Ireland",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/a/armstda01.html",
        "playerName": "Darrell Armstrong",
        "dateOfBirth": "1968-06-22",
        "placeOfBirth": "Gastonia, North Carolina",
        "season": "2002-03",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/w/willimo01.html",
        "playerName": "Monty Williams",
        "dateOfBirth": "1971-10-08",
        "placeOfBirth": "Fredericksburg, Virginia",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/s/sasseje01.html",
        "playerName": "Jeryl Sasser",
        "dateOfBirth": "1979-02-13",
        "placeOfBirth": "Dallas, Texas",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/r/reiddo01.html",
        "playerName": "Don Reid",
        "dateOfBirth": "1973-12-30",
        "placeOfBirth": "Washington, District of Columbia",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/o/outlabo01.html",
        "playerName": "Bo Outlaw",
        "dateOfBirth": "1971-04-13",
        "placeOfBirth": "San Antonio, Texas",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/m/millemi01.html",
        "playerName": "Mike Miller",
        "dateOfBirth": "1980-02-19",
        "placeOfBirth": "Mitchell, South Dakota",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/j/jacksja01.html",
        "playerName": "Jaren Jackson",
        "dateOfBirth": "1967-10-27",
        "placeOfBirth": "New Orleans, Louisiana",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/huntest01.html",
        "playerName": "Steven Hunter",
        "dateOfBirth": "1981-10-31",
        "placeOfBirth": "Chicago, Illinois",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/hudsotr01.html",
        "playerName": "Troy Hudson",
        "dateOfBirth": "1976-03-13",
        "placeOfBirth": "Carbondale, Illinois",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/hillgr01.html",
        "playerName": "Grant Hill",
        "dateOfBirth": "1972-10-05",
        "placeOfBirth": "Dallas, Texas",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/grantho01.html",
        "playerName": "Horace Grant",
        "dateOfBirth": "1965-07-04",
        "placeOfBirth": "Augusta, Georgia",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/garripa01.html",
        "playerName": "Pat Garrity",
        "dateOfBirth": "1976-08-23",
        "placeOfBirth": "Las Vegas, Nevada",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/e/ewingpa01.html",
        "playerName": "Patrick Ewing",
        "dateOfBirth": "1962-08-05",
        "placeOfBirth": "Kingston, Jamaica",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/d/declean01.html",
        "playerName": "Andrew DeClercq",
        "dateOfBirth": "1973-02-01",
        "placeOfBirth": "Detroit, Michigan",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/b/buechju01.html",
        "playerName": "Jud Buechler",
        "dateOfBirth": "1968-06-19",
        "placeOfBirth": "San Diego, California",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/b/brownde01.html",
        "playerName": "Dee Brown",
        "dateOfBirth": "1968-11-29",
        "placeOfBirth": "Jacksonville, Florida",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/a/armstda01.html",
        "playerName": "Darrell Armstrong",
        "dateOfBirth": "1968-06-22",
        "placeOfBirth": "Gastonia, North Carolina",
        "season": "2001-02",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/w/willimo01.html",
        "playerName": "Monty Williams",
        "dateOfBirth": "1971-10-08",
        "placeOfBirth": "Fredericksburg, Virginia",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/r/robinja02.html",
        "playerName": "James Robinson",
        "dateOfBirth": "1970-08-31",
        "placeOfBirth": "Jackson, Mississippi",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/r/reiddo01.html",
        "playerName": "Don Reid",
        "dateOfBirth": "1973-12-30",
        "placeOfBirth": "Washington, District of Columbia",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/p/perryel01.html",
        "playerName": "Elliot Perry",
        "dateOfBirth": "1969-03-28",
        "placeOfBirth": "Memphis, Tennessee",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/o/outlabo01.html",
        "playerName": "Bo Outlaw",
        "dateOfBirth": "1971-04-13",
        "placeOfBirth": "San Antonio, Texas",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/m/millemi01.html",
        "playerName": "Mike Miller",
        "dateOfBirth": "1980-02-19",
        "placeOfBirth": "Mitchell, South Dakota",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/hudsotr01.html",
        "playerName": "Troy Hudson",
        "dateOfBirth": "1976-03-13",
        "placeOfBirth": "Carbondale, Illinois",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/h/hillgr01.html",
        "playerName": "Grant Hill",
        "dateOfBirth": "1972-10-05",
        "placeOfBirth": "Dallas, Texas",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/g/garripa01.html",
        "playerName": "Pat Garrity",
        "dateOfBirth": "1976-08-23",
        "placeOfBirth": "Las Vegas, Nevada",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/d/doleami01.html",
        "playerName": "Michael Doleac",
        "dateOfBirth": "1977-06-15",
        "placeOfBirth": "San Antonio, Texas",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/d/declean01.html",
        "playerName": "Andrew DeClercq",
        "dateOfBirth": "1973-02-01",
        "placeOfBirth": "Detroit, Michigan",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/b/brownde01.html",
        "playerName": "Dee Brown",
        "dateOfBirth": "1968-11-29",
        "placeOfBirth": "Jacksonville, Florida",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/a/armstda01.html",
        "playerName": "Darrell Armstrong",
        "dateOfBirth": "1968-06-22",
        "placeOfBirth": "Gastonia, North Carolina",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/a/amaecjo01.html",
        "playerName": "John Amaechi",
        "dateOfBirth": "1970-11-26",
        "placeOfBirth": "Boston, Massachusetts",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/a/alexaco01.html",
        "playerName": "Cory Alexander",
        "dateOfBirth": "1973-06-22",
        "placeOfBirth": "Waynesboro, Virginia",
        "season": "2000-01",
        "atTeam": "Orlando Magic"
      },
      {
        "playerId": "/players/w/workmha01.html",
        "playerName": "Haywoode Workman",
        "dateOfBirth": "1966-01-23",
        "placeOfBirth": "Charlotte, North Carolina",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/willike02.html",
        "playerName": "Kevin Willis",
        "dateOfBirth": "1962-09-06",
        "placeOfBirth": "Los Angeles, California",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/willial02.html",
        "playerName": "Alvin Williams",
        "dateOfBirth": "1974-08-06",
        "placeOfBirth": "Philadelphia, Pennsylvania",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/t/thomajo02.html",
        "playerName": "John Thomas",
        "dateOfBirth": "1975-09-08",
        "placeOfBirth": "Minneapolis, Minnesota",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/s/stewami01.html",
        "playerName": "Michael Stewart",
        "dateOfBirth": "1975-04-25",
        "placeOfBirth": "Cucq-Trepied-Stella-Plage, France",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/r/radojal01.html",
        "playerName": "Aleksandar Radojević",
        "dateOfBirth": "1976-08-08",
        "placeOfBirth": "Herceg Novi, Montenegro",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/o/oaklech01.html",
        "playerName": "Charles Oakley",
        "dateOfBirth": "1963-12-18",
        "placeOfBirth": "Cleveland, Ohio",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/m/marksse01.html",
        "playerName": "Sean Marks",
        "dateOfBirth": "1975-08-23",
        "placeOfBirth": "Auckland, New Zealand",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/l/langan02.html",
        "playerName": "Antonio Lang",
        "dateOfBirth": "1972-05-15",
        "placeOfBirth": "Mobile, Alabama",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/d/davisan01.html",
        "playerName": "Antonio Davis",
        "dateOfBirth": "1968-10-31",
        "placeOfBirth": "Oakland, California",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/c/curryde01.html",
        "playerName": "Dell Curry",
        "dateOfBirth": "1964-06-25",
        "placeOfBirth": "Harrisonburg, Virginia",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/c/chrisdo01.html",
        "playerName": "Doug Christie",
        "dateOfBirth": "1970-05-09",
        "placeOfBirth": "Seattle, Washington",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/c/cartevi01.html",
        "playerName": "Vince Carter",
        "dateOfBirth": "1977-01-26",
        "placeOfBirth": "Daytona Beach, Florida",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/b/brownde01.html",
        "playerName": "Dee Brown",
        "dateOfBirth": "1968-11-29",
        "placeOfBirth": "Jacksonville, Florida",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/b/boguemu01.html",
        "playerName": "Muggsy Bogues",
        "dateOfBirth": "1965-01-09",
        "placeOfBirth": "Baltimore, Maryland",
        "season": "1999-00",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/willimi02.html",
        "playerName": "Micheal Williams",
        "dateOfBirth": "1966-07-23",
        "placeOfBirth": "Dallas, Texas",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/willike02.html",
        "playerName": "Kevin Willis",
        "dateOfBirth": "1962-09-06",
        "placeOfBirth": "Los Angeles, California",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/willial02.html",
        "playerName": "Alvin Williams",
        "dateOfBirth": "1974-08-06",
        "placeOfBirth": "Philadelphia, Pennsylvania",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/wallajo01.html",
        "playerName": "John Wallace",
        "dateOfBirth": "1974-02-09",
        "placeOfBirth": "Rochester, New York",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/t/thomajo02.html",
        "playerName": "John Thomas",
        "dateOfBirth": "1975-09-08",
        "placeOfBirth": "Minneapolis, Minnesota",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/s/stewami01.html",
        "playerName": "Michael Stewart",
        "dateOfBirth": "1975-04-25",
        "placeOfBirth": "Cucq-Trepied-Stella-Plage, France",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/s/slatere01.html",
        "playerName": "Reggie Slater",
        "dateOfBirth": "1970-08-27",
        "placeOfBirth": "Houston, Texas",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/o/oaklech01.html",
        "playerName": "Charles Oakley",
        "dateOfBirth": "1963-12-18",
        "placeOfBirth": "Cleveland, Ohio",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/m/marksse01.html",
        "playerName": "Sean Marks",
        "dateOfBirth": "1975-08-23",
        "placeOfBirth": "Auckland, New Zealand",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/k/knighne01.html",
        "playerName": "Negele Knight",
        "dateOfBirth": "1967-03-06",
        "placeOfBirth": "Detroit, Michigan",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/c/cunniwi01.html",
        "playerName": "William Cunningham",
        "dateOfBirth": "1974-03-25",
        "placeOfBirth": "Augusta, Georgia",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/c/chrisdo01.html",
        "playerName": "Doug Christie",
        "dateOfBirth": "1970-05-09",
        "placeOfBirth": "Seattle, Washington",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/c/cartevi01.html",
        "playerName": "Vince Carter",
        "dateOfBirth": "1977-01-26",
        "placeOfBirth": "Daytona Beach, Florida",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/b/brownde01.html",
        "playerName": "Dee Brown",
        "dateOfBirth": "1968-11-29",
        "placeOfBirth": "Jacksonville, Florida",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/b/bakerla01.html",
        "playerName": "Mark Baker",
        "dateOfBirth": "1969-11-11",
        "placeOfBirth": "Dayton, Ohio",
        "season": "1998-99",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/wrighsh01.html",
        "playerName": "Sharone Wright",
        "dateOfBirth": "1973-01-30",
        "placeOfBirth": "Macon, Georgia",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/williwa02.html",
        "playerName": "Walt Williams",
        "dateOfBirth": "1970-04-16",
        "placeOfBirth": "Washington, District of Columbia",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/willial02.html",
        "playerName": "Alvin Williams",
        "dateOfBirth": "1974-08-06",
        "placeOfBirth": "Philadelphia, Pennsylvania",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/w/wallajo01.html",
        "playerName": "John Wallace",
        "dateOfBirth": "1974-02-09",
        "placeOfBirth": "Rochester, New York",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/t/trentga01.html",
        "playerName": "Gary Trent",
        "dateOfBirth": "1974-09-22",
        "placeOfBirth": "Columbus, Ohio",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/t/thomajo02.html",
        "playerName": "John Thomas",
        "dateOfBirth": "1975-09-08",
        "placeOfBirth": "Minneapolis, Minnesota",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/t/tabakza01.html",
        "playerName": "Žan Tabak",
        "dateOfBirth": "1970-06-15",
        "placeOfBirth": "Split, Croatia",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/s/stoudda01.html",
        "playerName": "Damon Stoudamire",
        "dateOfBirth": "1973-09-03",
        "placeOfBirth": "Portland, Oregon",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/s/stokeed01.html",
        "playerName": "Ed Stokes",
        "dateOfBirth": "1971-09-03",
        "placeOfBirth": "Syracuse, New York",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/s/slatere01.html",
        "playerName": "Reggie Slater",
        "dateOfBirth": "1970-08-27",
        "placeOfBirth": "Houston, Texas",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/r/rogerro02.html",
        "playerName": "Roy Rogers",
        "dateOfBirth": "1973-08-19",
        "placeOfBirth": "Linden, Alabama",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/r/rogerca01.html",
        "playerName": "Carlos Rogers",
        "dateOfBirth": "1971-02-06",
        "placeOfBirth": "Detroit, Michigan",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/r/respesh01.html",
        "playerName": "Shawn Respert",
        "dateOfBirth": "1972-02-06",
        "placeOfBirth": "Detroit, Michigan",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/m/milleol01.html",
        "playerName": "Oliver Miller",
        "dateOfBirth": "1970-04-06",
        "placeOfBirth": "Fort Worth, Texas",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/m/mccanbo01.html",
        "playerName": "Bob McCann",
        "dateOfBirth": "1964-04-22",
        "placeOfBirth": "Morristown, New Jersey",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/k/kemptti01.html",
        "playerName": "Tim Kempton",
        "dateOfBirth": "1964-01-25",
        "placeOfBirth": "Jamaica, New York",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/j/jonespo01.html",
        "playerName": "Popeye Jones",
        "dateOfBirth": "1970-06-17",
        "placeOfBirth": "Dresden, Tennessee",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/g/garnech01.html",
        "playerName": "Chris Garner",
        "dateOfBirth": "1975-02-02",
        "placeOfBirth": "Memphis, Tennessee",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/d/daniell01.html",
        "playerName": "Lloyd Daniels",
        "dateOfBirth": "1967-09-04",
        "placeOfBirth": "Brooklyn, New York",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/c/chrisdo01.html",
        "playerName": "Doug Christie",
        "dateOfBirth": "1970-05-09",
        "placeOfBirth": "Seattle, Washington",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/c/cambyma01.html",
        "playerName": "Marcus Camby",
        "dateOfBirth": "1974-03-22",
        "placeOfBirth": "Hartford, Connecticut",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/b/brownde01.html",
        "playerName": "Dee Brown",
        "dateOfBirth": "1968-11-29",
        "placeOfBirth": "Jacksonville, Florida",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      },
      {
        "playerId": "/players/b/billuch01.html",
        "playerName": "Chauncey Billups",
        "dateOfBirth": "1976-09-25",
        "placeOfBirth": "Denver, Colorado",
        "season": "1997-98",
        "atTeam": "Toronto Raptors"
      }
    ]
  };
  