import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject } from 'rxjs';
import { PlayerPerformanceStat } from '../player';
import { DbService } from '../db.service';

const PERFOMACE_STAT_SORT_COLUMN = [
'season',
'age',
'teamAbbrvName',
'league',
'position',
'eFGP',
'g',
'gs',
'mp',
'fg',
'fga',
'fgp',
'ft',
'fta',
'ftp',
'orb',
'drb',
'trb',
'ast',
'stl',
'blk',
'tov',
'pf',
'pts',
'3p',
'3pa',
'3pp',
'2p',
'2pa',
'2pp'];

export interface PerformanceTableItem extends PlayerPerformanceStat{

}

/**
 * Data source for the PerformanceTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class PerformanceTableDataSource extends DataSource<PerformanceTableItem> {
  private dataSubject = new BehaviorSubject<PerformanceTableItem[]>([]);
  public data$ = this.dataSubject.asObservable(); 
  public dataLength$: Observable<number> = this.data$.pipe(map(data => data.length));
  paginator: MatPaginator;
  sort: MatSort;

  constructor(
    private playerId: string,
    private dbService: DbService) {
    super();
    this.downloadData();
  }

  downloadData() {
    this.dbService.getPerformanceStat(this.playerId).subscribe(
        data => {
          this.dataSubject.next(data);
        }
      )
  
    }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<PerformanceTableItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.data$,
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      let data = this.dataSubject.getValue();
      this.paginator.length = data.length;
      return this.getPagedData(this.getSortedData([...data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.dataSubject.complete();
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: PerformanceTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: PerformanceTableItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      // switch (this.sort.active) {
        // case 'name': return compare(a.name, b.name, isAsc);
        // case 'id': return compare(+a.id, +b.id, isAsc);
        // default: return 0;
      // }
      if (PERFOMACE_STAT_SORT_COLUMN.includes(this.sort.active)) {
        let columnName = this.sort.active;
        return compare(a[columnName], b[columnName], isAsc);
      } else {
        return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number | Date, b: string | number | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

