export const PLAYER_BASIC: Object = {
  "total": 1,
  "msg": "success",
  "rows": [
    {
      "playerId": "/players/d/duranke01.html",
      "playerUrl": "https://www.basketball-reference.com/players/d/duranke01.html",
      "playerName": "Kevin Durant",
      "playerFullName": "Kevin Wayne Durant",
      "dateOfBirth": "1988-09-29",
      "placeOfBirth": "Washington, District of Columbia",
      "height": 208.0,
      "weight": 108.0,
      "college": "Texas",
      "highSchool": "Montrose Christian School (Rockville, Maryland)",
      "dominantHand": "right",
      "wikidataId": "wd:Q29545",
      "playerEspnUrl": "https://www.espn.com/nba/player/_/id/3202/kevin-durant"
    }
  ]
}
