import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, Subscription, BehaviorSubject } from 'rxjs';
import { PlayerBasicRecord, PlayerCompactRecord } from "../player";
import { DbService } from '../db.service';


export interface PlayerTableItem extends PlayerCompactRecord {

}

// TODO: replace this with real data from your application


/**
 * Data source for the PlayerTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class PlayerTableDataSource extends DataSource<PlayerTableItem> {
  private dataSubject = new BehaviorSubject<PlayerTableItem[]>([]);
  private filterNamePrefixSubject = new BehaviorSubject<string>("");
  public data$ = this.dataSubject.asObservable();
  public filterNamePrefix$ = this.filterNamePrefixSubject.asObservable();

  paginator: MatPaginator;
  sort: MatSort;
  
  constructor(private dbService: DbService) {
    super();
  }
downloadData() {
  this.dbService.getPlayerList().subscribe(
      data => {
        this.dataSubject.next(data);
      }
    )

  }

  public updateFilter(newFilter: string) {
    this.filterNamePrefixSubject.next(newFilter == null ? "" : newFilter);
  }
  private filterByNamePrefix(item: PlayerCompactRecord, prefix: string) {
    
    return item.playerName.toLowerCase().startsWith(prefix.toLowerCase());
  }
  /** 
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<PlayerTableItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.

    const dataMutations = [
      this.filterNamePrefix$,
      this.data$,
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      let filterNamePrefix = this.filterNamePrefixSubject.getValue();
      let data = this.dataSubject.getValue();
      let filteredData = data.filter(item => this.filterByNamePrefix(item, filterNamePrefix ));  
      this.paginator.length = filteredData.length;
      return this.getPagedData(this.getSortedData([...filteredData]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.dataSubject.complete();
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: PlayerTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: PlayerTableItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'player_name': return compare(a.playerName, b.playerName, isAsc);
        case 'player_full_name': return compare(a.playerFullName, b.playerFullName, isAsc);
        case 'date_of_birth': return compare(a.dateOfBirth, b.dateOfBirth, isAsc);
        case 'place_of_birth': return compare(a.placeOfBirth, b.placeOfBirth, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number | Date, b: string | number | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
