import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject } from 'rxjs';
import { DbService } from '../db.service';
import { ServedTeamRecord } from '../player';
import { PlayerTableItem } from '../player-table/player-table-datasource';

// TODO: Replace this with your own data model type
export interface TeamTableItem extends ServedTeamRecord{

}


/**
 * Data source for the TeamTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class TeamTableDataSource extends DataSource<TeamTableItem> {
  private dataSubject = new BehaviorSubject<ServedTeamRecord[]>([]);
  public data$ = this.dataSubject.asObservable(); 
  public dataLength$: Observable<number> = this.data$.pipe(map(data => data.length));
  sort: MatSort;
  paginator: MatPaginator;

  constructor(
    private playerId: string,
    private dbService: DbService) {
    super();
    this.downloadData();
  }

  downloadData() {
    this.dbService.getServedTeams(this.playerId).subscribe(
        data => {
          this.dataSubject.next(data);
        }
      )
  
    }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<TeamTableItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.data$,
      this.paginator.page,
      this.sort.sortChange
    ];

    return merge(...dataMutations).pipe(map(() => {
      let data = this.dataSubject.getValue();
      this.paginator.length = data.length;
      return this.getPagedData(this.getSortedData([...data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.dataSubject.complete();
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: TeamTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: TeamTableItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'teamName': return compare(a.teamName, b.teamName, isAsc);
        case 'teamAbbrvName': return compare(a.teamAbbrvName, b.teamAbbrvName, isAsc);
        case 'season': return compare(a.season, b.season, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
