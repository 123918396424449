<div *ngIf="playerBasic != null" class="container-fluid full-width">
    <div class="row justify-content-center mat-primary full-width player-banner name-header">
        <div class="col-8 mat-display-3 m-auto pt-5 pb-2 mat-background-primary">
            {{playerBasic?.playerName}}
        </div>
    </div>

    <div class="player-banner-spacer mat-display-3">
    </div>

    <div class="row mt-2">
        <div class="col-8 m-auto">
            <div class="container-fluid p-0 info-card">
                <!-- <div class="row p-0 m-0 bold">
                    <h1 class="pr-2 mat-display-1"> Basic Information </h1>
                    <p *ngIf="playerBasic.playerEspnUrl != null" class="mat-caption badge badge-info ">ESPN Link</p>
                </div> -->

                <h1 class="row p-0 m-0 bold mat-display-1">
                    Basic Information
                    <!-- <a *ngIf="playerBasic?.playerEspnUrl != null" class="badge badge-info px-2" href="{{playerBasic?.playerEspnUrl}}">ESPN</a>
                    <a *ngIf="playerBasic?.wikidataId != null" class="badge badge-info px-2" href="{{getWikiDataUrl(playerBasic?.wikidataId)}}">Wikidata</a>
                    <a *ngIf="playerBasic?.playerUrl != null" class="badge badge-info px-2" href="{{playerBasic?.playerUrl}}">Basketball Reference</a> -->
                </h1>

                <div class="row p-0 m-0 justify-content-start mat-body">
                    <a *ngIf="playerBasic?.playerUrl != null" class="badge badge-info px-2 mx-1 basketball-reference" href="{{playerBasic?.playerUrl}}">Basketball Reference</a>
                    <a *ngIf="playerBasic?.wikidataId != null" class="badge badge-info px-2 mx-1 wikidata" href="{{getWikiDataUrl(playerBasic?.wikidataId)}}">Wikidata</a>
                    <a *ngIf="playerBasic?.playerEspnUrl != null" class="badge badge-info px-2 mx-1 espn" href="{{playerBasic?.playerEspnUrl}}">ESPN</a>
                </div>

                <div class="row p-0 m-0 pt-2 justify-content-start">
                    <p class="col-6 m-auto">
                        <span class="mat-body-strong">Full Name: </span>
                        <span class="mat-body">{{playerBasic?.playerFullName}}</span>
                    </p>
                    <p class="col-6 m-auto">
                        <span class="mat-body-strong">Dominant Hand: </span>
                        <span class="mat-body">{{playerBasic?.dominantHand}}</span>
                    </p>
                </div>

                <div class="row p-0 m-0 pt-2 justify-content-start">
                    <p class="col-6 m-auto">
                        <span class="mat-body-strong">Date of Birth: </span>
                        <span class="mat-body">{{playerBasic?.dateOfBirth | date: 'yyyy-MM-dd'}}</span>
                    </p>
                    <p class="col-6 m-auto">
                        <span class="mat-body-strong">Place of Birth: </span>
                        <span class="mat-body">{{playerBasic?.placeOfBirth}}</span>
                    </p>
                </div>

                <div class="row p-0 m-0 pt-2 justify-content-start">
                    <p class="col-6 m-auto">
                        <span class="mat-body-strong">Height: </span>
                        <span class="mat-body">{{playerBasic?.height | number: ('1.0-1')}} cm</span>
                    </p>
                    <p class="col-6 m-auto">
                        <span class="mat-body-strong">Weight: </span>
                        <span class="mat-body">{{playerBasic?.weight | number: '1.0-1'}} kg</span>
                    </p>
                </div>

                <div class="row p-0 m-0 pt-2 justify-content-start">
                    <p class="col-6 m-auto">
                        <span class="mat-body-strong">College: </span>
                        <span class="mat-body">{{playerBasic?.college == null ? 'N/A' : playerBasic.college}}</span>
                    </p>
                    <p class="col-6 m-auto">
                        <span class="mat-body-strong">High School: </span>
                        <span class="mat-body">{{playerBasic?.highSchool == null ? 'N/A' : playerBasic.highSchool}}</span>
                    </p>
                </div>

            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-8 m-auto">
            <div class="container-fluid p-0 info-card">
                <h1 class="row mat-display-1 p-0 m-0">
                    Performance
                </h1>

            </div>
        </div>
    </div>

    <div class="row mt-0">
        <div class="col-8 m-auto">
            <app-performance-table class="m-0 pt-2 overflow-scroll" [playerId]="playerBasic.playerId"></app-performance-table>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-8 m-auto">
            <div class="container-fluid p-0 info-card">
                <h1 class="row mat-display-1 p-0 m-0">
                    Served Team
                </h1>
                <div class="row p-0 m-0">
                    <app-team-table class="m-auto pt-2 detail-table" [playerId]="playerBasic.playerId"></app-team-table>
                </div>
            </div>
        </div>
    </div>


    <app-similar-players-table class="row mt-5" [playerId]="playerBasic.playerId"></app-similar-players-table>

    <!-- 
    <div class="row mt-5">
        <div class="col-8 m-auto">
            <div class="container-fluid p-0 info-card">
                <h1 class="row mat-display-1 p-0 m-0">
                    Similar Players
                </h1>
                <div class="row p-0 m-0">
                    <app-similar-players-table class="m-auto pt-2 detail-table" [playerId]="playerBasic.playerId"></app-similar-players-table>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row mt-5">
        <div class="col-8 m-auto">
            <div class="container-fluid p-0 info-card">
                <h1 class="row mat-display-1 p-0 m-0">
                    Teammates
                </h1>
                <div class="row p-0 m-0">
                    <app-teammates-table class="m-auto pt-2 flex-grow-1" [playerId]="playerBasic.playerId"></app-teammates-table>
                </div>
            </div>
        </div>
    </div>



    <div class="row mt-5">
        <div class="col-8 m-auto">
            <div class="container-fluid p-0 info-card">
                <h1 class="row mat-display-1 p-0 m-0">
                    Coworked Coaches
                </h1>
                <div class="row p-0 m-0">
                    <app-coaches-table class="m-auto pt-2 flex-grow-1" [playerId]="playerBasic.playerId"></app-coaches-table>
                </div>
            </div>
        </div>
    </div>

    <app-alumni-table class="row mt-5" [playerId]="playerBasic.playerId" [schoolKind]="'highSchool'"></app-alumni-table>
    <!-- <div class="row mt-5">
        <div class="col-8 m-auto">
            <div class="container-fluid p-0 info-card">
                <h1 class="row mat-display-1 p-0 m-0">
                    High School Alumni
                </h1>
                <div class="row p-0 m-0">
                    <app-alumni-table class="m-auto pt-2 flex-grow-1" [playerId]="playerBasic.playerId" [schoolKind]="'highSchool'"></app-alumni-table>
                </div>
            </div>
        </div>
    </div> -->

    <!-- 
    <div class="row mt-5">
        <div class="col-8 m-auto">
            <div class="container-fluid p-0 info-card">
                <h1 class="row mat-display-1 p-0 m-0">
                    College Alumni
                </h1>
                <div class="row p-0 m-0">
                    <app-alumni-table class="m-auto pt-2 flex-grow-1" [playerId]="playerBasic.playerId" [schoolKind]="'college'"></app-alumni-table>
                </div>
            </div>
        </div>
    </div> -->
    <app-alumni-table class="row mt-5" [playerId]="playerBasic.playerId" [schoolKind]="'college'"></app-alumni-table>


</div>

<div class="h-25"></div>