<div class="row mt-5" [style.display]="(isEmptyTable$ | async) ? 'none' : 'contents'">
    <div class="col-8 m-auto">
        <div class="container-fluid p-0 info-card">
            <h1 class="row mat-display-1 p-0 m-0">
                Similar Players
            </h1>
            <div class="row p-0 mt-2">
                <div class="col-12 m-auto pt-2 detail-table">
                    <table mat-table class="full-width-table p-1" matSort aria-label="Elements">
                        <!-- Name Column -->
                        <ng-container matColumnDef="playerName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class='mat-h3'>Name</th>
                            <td mat-cell *matCellDef="let row" class="mat-primary">
                                <a href="nba/player/{{row.playerId | shortPlayerId }}">{{row.playerName}}</a>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="dateOfBirth">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Birth </th>
                            <td mat-cell *matCellDef="let row">
                                {{row.dateOfBirth | date: 'yyyy-MM-dd'}}
                                <!-- {{row.dateOfBirth}} -->
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="placeOfBirth">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Place of Birth </th>
                            <td mat-cell *matCellDef="let row"> {{row.placeOfBirth}} </td>
                        </ng-container>

                        <ng-container matColumnDef="similarity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Similarity </th>
                            <td mat-cell *matCellDef="let row"> {{row.similarity | number: '0.0-4'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="maxSingleStatDistance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Max. stat distance</th>
                            <td mat-cell *matCellDef="let row"> {{row.maxSingleStatDistance | number: '0.0-4'}} </td>
                        </ng-container>

                        <tr mat-header-row class="mat-primary" *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator #paginator [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 250]">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>