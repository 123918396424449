export const PLAYER_SERVED_TEAMS: Object = {
    "total": 17,
    "rows": [
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2003-04"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2004-05"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2005-06"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2006-07"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2007-08"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2008-09"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2009-10"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Miami Heat",
        "teamAbbrvName": "MIA",
        "season": "2010-11"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Miami Heat",
        "teamAbbrvName": "MIA",
        "season": "2011-12"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Miami Heat",
        "teamAbbrvName": "MIA",
        "season": "2012-13"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Miami Heat",
        "teamAbbrvName": "MIA",
        "season": "2013-14"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2014-15"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2015-16"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2016-17"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Cleveland Cavaliers",
        "teamAbbrvName": "CLE",
        "season": "2017-18"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Los Angeles Lakers",
        "teamAbbrvName": "LAL",
        "season": "2018-19"
      },
      {
        "playerId": "/players/j/jamesle01.html",
        "playerName": "LeBron James",
        "teamName": "Los Angeles Lakers",
        "teamAbbrvName": "LAL",
        "season": "2019-20"
      }
    ]
};