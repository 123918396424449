import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainpageComponent } from './mainpage/mainpage.component';
import { PlayerDetailsPageComponent } from './player-details-page/player-details-page.component';
import { PlayerListpageComponent } from './player-listpage/player-listpage.component';
import { PlayerTableComponent } from './player-table/player-table.component';
import { PlayerpageComponent } from "./playerpage/playerpage.component";

const routes: Routes = [
  { path: 'player', component: PlayerListpageComponent, pathMatch: 'full'},
  { path: 'player/:id', component: PlayerDetailsPageComponent},
  { path: '', redirectTo: '/player', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
