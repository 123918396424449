import { Component, Input, OnInit } from '@angular/core';
import { stringify } from 'querystring';
import { AlumniTableDataSource } from '../alumni-table/alumni-table-datasource';
import { DbService } from '../db.service';
import { PlayerBasicRecord } from '../player';

@Component({
  selector: 'app-player-details',
  templateUrl: './player-details.component.html',
  styleUrls: ['./player-details.component.css']
})
export class PlayerDetailsComponent implements OnInit {

  @Input() playerBasic: PlayerBasicRecord;
  highSchoolDataSource: AlumniTableDataSource;
  collegeDataSource: AlumniTableDataSource;

  constructor(
    private dbService: DbService
  ) { }

  ngOnInit(): void {
    this.highSchoolDataSource = new AlumniTableDataSource(this.playerBasic.playerId, "highSchool" , this.dbService);
    this.collegeDataSource = new AlumniTableDataSource(this.playerBasic.playerId, "college" , this.dbService);
  }

  getWikiDataUrl(wikiDataId: string): string {
    if (wikiDataId == null) {
      return "#";
    }
    return wikiDataId.replace("wd:", "https://www.wikidata.org/wiki/")
  }
}
