<div [style.visibility]="(isEmptyTable$ | async) ? 'hidden' : 'visible'">
    <table mat-table class="full-width-table" matSort aria-label="Elements">

        <ng-container matColumnDef="coachName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row" class="mat-primary">{{row.coachName}}</td>
        </ng-container>

        <ng-container matColumnDef="coachTitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Title </th>
            <td mat-cell *matCellDef="let row">
                {{row.coachTitle }}
                <!-- {{row.dateOfBirth}} -->
            </td>
        </ng-container>

        <ng-container matColumnDef="season">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Season</th>
            <td mat-cell *matCellDef="let row"> {{row.season}} </td>
        </ng-container>

        <ng-container matColumnDef="atTeam">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Team </th>
            <td mat-cell *matCellDef="let row"> {{row.atTeam}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]">
    </mat-paginator>
</div>

<div *ngIf="isEmptyTable$ | async">
    No Data
</div>